import { useState, useEffect, useMemo } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { Device, DeviceGroup } from "../types";
import { constants } from "common/constants";
import { useContentStyles } from "common/styles/useContentStyles";
import { RemoteAccessType } from "common/enums";
import { hasPermission, isEndUser } from "common/helpers/utils";
import NoRowsOverlay from "common/components/NoRowsOverlay";

type DevicesTableProps = {
  data: Device[];
  remoteAccessType?: RemoteAccessType;
};

const GroupDevicesSubTable: React.FC<DevicesTableProps> = (props) => {
  const classes = useContentStyles();
  const [rows, setRows] = useState<Device[]>(props.data);

  useEffect(() => {
    setRows(props.data);
  }, [props.data]);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "name",
        type: "string",
        headerName: "Device Name",
        width: 250,
      },
      {
        field: "alias",
        type: "string",
        headerName: "Alias(es)",
        flex: 1,
      },
      {
        field: "groups",
        type: "string",
        headerName: "Group(s)",
        flex: 1,
        valueGetter: (value) => {
          const groups = (value || []) as unknown as DeviceGroup[];
          return groups.map((obj) => obj.name).join(", ");
        },
      },
      {
        field: "siteName",
        type: "string",
        headerName: "Site",
        flex: 1,
      },
      { field: "type", type: "string", headerName: "Type", flex: 1 },
      {
        field: "manufacturerName",
        type: "string",
        headerName: "Manufacturer",
        flex: 1,
      },
    ],
    []
  );
  // Filter columns based on user role
  const filteredColumns = isEndUser()
    ? columns.filter((column) => column.field !== "groups")
    : columns;

  return (
    <>
      <DataGrid
        disableVirtualization
        columns={filteredColumns}
        rows={rows}
        getRowId={(row) => row.deviceId}
        hideFooter={true}
        hideFooterPagination={true}
        autoHeight
        slots={{
          noRowsOverlay: () => (
            <NoRowsOverlay
              hasAccess={hasPermission("devices.summary", "read")}
              name="Devices"
            />
          ),
        }}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "600",
          },
        }}
        getRowClassName={(params) =>
          params.row.type.toLowerCase() === constants.DEVICE_TYPE_GATEWAY
            ? classes.rowColor
            : ""
        }
      />
    </>
  );
};
export default GroupDevicesSubTable;
