import React, { useState } from "react";
import { Stack, Switch, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setSnackbarToast } from "redux/UiStateSlice";

import StaticToDynamicDialog from "./StaticToDynamicDialog";
import DynamicToStaticDialog from "./DynamicToStaticDialog";
import { User, UsersFilterData } from "./types";
import { UserGroupType } from "common/enums";

type GroupTypeProps = {
  onCancel: (type: UserGroupType) => void;
  onConfirmSave: (type: UserGroupType) => void;
  rows: User[];
  rowSelectionModel: string[];
  defaultGroupType: UserGroupType;
  isFilterSubmitted: boolean;
  filters: UsersFilterData;
};

const GroupType: React.FC<GroupTypeProps> = ({
  onCancel,
  onConfirmSave,
  rows,
  rowSelectionModel,
  defaultGroupType,
  isFilterSubmitted,
  filters,
}) => {
  const [showStaticToDynamicDialog, setShowStaticToDynamicDialog] =
    useState(false);
  const [showDynamicToStaticDialog, setShowDynamicToStaticDialog] =
    useState(false);
  const [groupTypeSwitchValue, setGroupTypeSwitchValue] =
    useState(defaultGroupType);
  const [newAddedUsersOnTypeChange, setNewAddedUsersOnTypeChange] = useState<
    User[]
  >([]);
  const dispatch = useDispatch();
  const isFilterSelected = () => {
    if (!isFilterSubmitted) {
      return false;
    }
    if (
      filters.organizations.length > 0 ||
      (filters.city && filters.city !== "") ||
      filters.roles.length > 0 ||
      filters.selectedCountries.length > 0 ||
      filters.selectedStates.length > 0 ||
      filters.statuses.length > 0
    ) {
      return true;
    }
  };
  const handleGroupTypeSwitchChange = () => {
    if (groupTypeSwitchValue === UserGroupType.STATIC) {
      //static to dynamic
      //show warning if user has not applied filter OR not selected all rows
      if (isFilterSelected() || rows.length === rowSelectionModel.length) {
        //find new added rows
        const newAddedUsers = rows.filter(
          (user) =>
            !rowSelectionModel.some(
              (originalObj) => originalObj === user.userId
            )
        );
        setNewAddedUsersOnTypeChange(newAddedUsers);
        setShowStaticToDynamicDialog(true);
      } else {
        dispatch(
          setSnackbarToast({
            message: "Please filter users before switching to Dynamic type",
            open: true,
            severity: "error",
          })
        );
      }
    } else {
      setShowDynamicToStaticDialog(true);
    }
  };

  const onConfirmStaticToDynamic = () => {
    setGroupTypeSwitchValue(UserGroupType.DYNAMIC);
    setShowStaticToDynamicDialog(false);
    onConfirmSave(UserGroupType.DYNAMIC);
  };

  const onCancelStaticToDynamic = () => {
    setShowStaticToDynamicDialog(false);
    onCancel(UserGroupType.STATIC);
  };

  const onConfirmDynamicToStatic = () => {
    setGroupTypeSwitchValue(UserGroupType.STATIC);
    setShowDynamicToStaticDialog(false);
    onConfirmSave(UserGroupType.STATIC);
  };

  const onCancelDynamicToStatic = () => {
    setShowDynamicToStaticDialog(false);
    onCancel(UserGroupType.DYNAMIC);
  };

  return (
    <React.Fragment>
      <Stack direction="row" alignItems="center">
        <Typography>
          <strong>Group Type: </strong>&nbsp;&nbsp;
        </Typography>
        <Typography variant="subtitle1">Static</Typography>
        <Switch
          checked={groupTypeSwitchValue === UserGroupType.DYNAMIC}
          onChange={handleGroupTypeSwitchChange}
        />
        <Typography variant="subtitle1">Dynamic</Typography>
      </Stack>

      {showStaticToDynamicDialog && (
        <StaticToDynamicDialog
          newUsers={newAddedUsersOnTypeChange}
          onConfirm={onConfirmStaticToDynamic}
          open={showStaticToDynamicDialog}
          onCancel={onCancelStaticToDynamic}
        ></StaticToDynamicDialog>
      )}

      {showDynamicToStaticDialog && (
        <DynamicToStaticDialog
          onConfirm={onConfirmDynamicToStatic}
          open={showDynamicToStaticDialog}
          onCancel={onCancelDynamicToStatic}
        ></DynamicToStaticDialog>
      )}
    </React.Fragment>
  );
};
export default GroupType;
