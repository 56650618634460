import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  CssBaseline,
  Card,
  Link,
  Typography,
  CardHeader,
  Drawer,
  Button,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { useDispatch } from "react-redux";
import { setSnackbarToast } from "redux/UiStateSlice";

import { useContentStyles } from "common/styles/useContentStyles";
import apiClient from "common/apiClientAxios";
import AddSiteOwner from "./AddSiteOwner";
import DeleteSiteOwner from "./DeleteSiteOwner";
import EditSiteOwner from "./EditSiteOwner";
import SiteOwnersTable from "./SiteOwnersTable";
import { DrawerHeader } from "common/styles/styledComponents";
import { SiteOwner } from "pages/settings/types";
import { hasPermission } from "common/helpers/utils";

const SiteOwners: React.FC = () => {
  const classes = useContentStyles();
  const timerRef = useRef<number>();
  const dispatch = useDispatch();
  const TIMEOUT = 1000;
  const [openForm, setOpenForm] = useState(false);
  const [isUpdate, setUpdate] = useState(true);
  const [isEdit, setEdit] = useState(false);
  const [selectedSiteOwner, setSelectedSiteOwner] = useState<SiteOwner>({
    ownerId: "",
    name: "",
  });
  const [openDialogue, setOpenDialogue] = React.useState(false);

  const [siteOwners, setSiteOwners] = useState<SiteOwner[]>([]);

  const updateTable = useCallback(async () => {
    try {
      const siteOwnersResponse = await apiClient.get("/site-owners");
      setSiteOwners(siteOwnersResponse.data.data as SiteOwner[]);
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (isUpdate) {
      updateTable();
      setUpdate(false);
    }
    return () => clearTimeout(timerRef.current);
  }, [isUpdate, updateTable]);

  const openAddForm = () => {
    setOpenForm(true);
  };
  const onCloseForm = () => {
    setOpenForm(false);
  };
  const onCloseEdit = () => {
    setEdit(false);
  };

  const onSaveUpdateTable = () => {
    timerRef.current = window.setTimeout(() => {
      setEdit(false);
      setOpenForm(false);
      setUpdate(true);
    }, TIMEOUT);
  };

  const onDeleteSiteOwner = async () => {
    try {
      if (selectedSiteOwner.ownerId) {
        const deleteResponse = await apiClient.delete(
          `/site-owners/${selectedSiteOwner.ownerId}`
        );
        dispatch(
          setSnackbarToast({
            message: deleteResponse.data.meta.message,
            open: true,
            severity: "success",
          })
        );
        onDeleteUpdateTable();
      }
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
      setOpenDialogue(false);
    }
  };

  const handleDeleteSiteOwner = (obj: SiteOwner) => {
    setSelectedSiteOwner(obj);
    setOpenDialogue(true);
  };

  const onDeleteUpdateTable = () => {
    setOpenDialogue(false);
    setUpdate(true);
  };

  const onCancelRemove = () => {
    setOpenDialogue(false);
  };

  const onEditSiteOwner = (obj: SiteOwner) => {
    setSelectedSiteOwner(obj);
    setEdit(true);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.contentPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="none" color="inherit">
            Resources
          </Link>
          <Typography variant="body1">{"Site Owners"}</Typography>
        </Breadcrumbs>
      </div>
      <Card elevation={0} className={classes.contentSection}>
        <CardHeader
          disableTypography
          title="SiteOwners"
          className={classes.cardHeader}
          action={
            <Button
              onClick={openAddForm}
              color="info"
              disabled={!hasPermission("owners", "write")}
              startIcon={<PlaylistAddIcon />}
            >
              {"Add Site Owner"}
            </Button>
          }
        ></CardHeader>
        <Drawer
          anchor="right"
          open={openForm}
          classes={{ paper: classes.formPaper }}
        >
          <DrawerHeader />
          <AddSiteOwner
            onSaveUpdateTable={onSaveUpdateTable}
            onClose={onCloseForm}
          ></AddSiteOwner>
        </Drawer>
        <Drawer
          anchor="right"
          open={isEdit}
          classes={{ paper: classes.formPaper }}
        >
          <DrawerHeader />
          <EditSiteOwner
            onSaveUpdateTable={onSaveUpdateTable}
            onClose={onCloseEdit}
            selectedSiteOwner={selectedSiteOwner}
          ></EditSiteOwner>
        </Drawer>
        {openDialogue && (
          <DeleteSiteOwner
            onDeleteSiteOwner={onDeleteSiteOwner}
            selectedSiteOwner={selectedSiteOwner}
            open={openDialogue}
            onCancelRemove={onCancelRemove}
          ></DeleteSiteOwner>
        )}
        <SiteOwnersTable
          data={siteOwners}
          handleEditSiteOwner={onEditSiteOwner}
          handleDeleteSiteOwner={handleDeleteSiteOwner}
        ></SiteOwnersTable>
      </Card>
    </React.Fragment>
  );
};

export default SiteOwners;
