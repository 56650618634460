import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  Checkbox,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { makeStyles } from "@mui/styles";

import TcTextField from "common/components/TcTextField";
import { Site } from "pages/sites/types";

const useStyles = makeStyles({
  informationItem: {
    display: "flex",
    marginBottom: "8px",
  },
  label: {
    width: "20%",
    fontWeight: "bold",
  },
  value: {
    flex: 1,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "8px",
    padding: "16px",
  },
  cardHeader: {
    fontSize: "20px",
    fontWeight: "normal",
  },
});

interface SiteWANTabProps {
  site?: Site;
}

type SiteWANForm = {
  apnSetting: string;
  automaticDNS: string;
  primaryDNS: string;
  secondaryDNS: string;
};

const formDefaultValues: SiteWANForm = {
  apnSetting: "we01.vzwstatic",
  automaticDNS: "yes",
  primaryDNS: "8.8.8.8",
  secondaryDNS: "",
};

const SiteWANTab: React.FC<SiteWANTabProps> = () => {
  const classes = useStyles();
  const methods = useForm<SiteWANForm>({
    defaultValues: formDefaultValues,
    mode: "onBlur",
  });
  return (
    <Card variant="outlined">
      <CardHeader
        title="WAN Configuration"
        className={classes.cardHeader}
        disableTypography
      />
      <CardContent>
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" gutterBottom>
                    {" Link Information"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Grid item xs={7}>
                    <TcTextField name="apnSetting" label="APN Setting" />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.informationItem}>
                    <Typography className={classes.label}>
                      IP Address
                    </Typography>
                    <Typography className={classes.value}>
                      104.107.62.205
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <Grid item xs={7}>
                    <Typography component="header" variant="body1" gutterBottom>
                      <label htmlFor="enable-checkbox">
                        {" Automatic DNS"}
                      </label>
                      <Checkbox
                        id="enable-checkbox"
                        name="automaticDNS"
                        color="primary"
                        defaultChecked
                        inputProps={{ "aria-label": "Automatic DNS" }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.informationItem}>
                    <Typography className={classes.label}>Netmask</Typography>
                    <Typography className={classes.value}>
                      255.255.240.0
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <Grid item xs={7}>
                    <TcTextField name="primaryDNS" label="Primary DNS" />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.informationItem}>
                    <Typography className={classes.label}>Gateway</Typography>
                    <Typography className={classes.value}>
                      104.107.62.203
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <Grid item xs={7}>
                    <TcTextField name="secondaryDNS" label="Secondary DNS" />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.informationItem}>
                    <Typography className={classes.label}>
                      DNS Server
                    </Typography>
                    <Typography className={classes.value}>
                      104.107.62.254
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      </CardContent>
    </Card>
  );
};

export default SiteWANTab;
