import React, { useCallback, useEffect, useRef, useState } from "react";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import green from "@mui/material/colors/green";
import grey from "@mui/material/colors/grey";
import {
  Avatar,
  Breadcrumbs,
  Card,
  CssBaseline,
  Grid,
  Typography,
  Link as MuiLink,
  useTheme,
  Box,
} from "@mui/material";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  User,
  UserFormMasterData,
  UserGroup,
  UserGroupNameForm,
  UpdateUserGroup,
  UsersFilterData,
} from "./types";
import UsersFilter from "./UsersFilter";
import { constants } from "common/constants";
import { useContentStyles } from "common/styles/useContentStyles";
import CustomToolbar from "common/components/CustomToolbar";
import { UserGroupType, UserStatus } from "common/enums";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import apiClient from "common/apiClientAxios";
import {
  getFilteredUserRows,
  hasMatchingObjects,
  hasPermission,
  stringAvatar,
} from "common/helpers/utils";
import AddUserGroup from "./groups/AddUserGroup";
import UserGroupTypeDialog from "./groups/UserGroupTypeDialog";
import GroupType from "./GroupType";
import NoRowsOverlay from "common/components/NoRowsOverlay";
import UsersFilterChipDisplay from "./UsersFilterChipDisplay";

const EditUserGroupTable: React.FC = () => {
  const classes = useContentStyles();
  const [rows, setRows] = useState<User[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { userGroup } = (location.state as { userGroup?: UserGroup }) || {};

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [userFormMasterData, setUserFormMasterData] =
    useState<UserFormMasterData>({
      organizations: [],
      groups: [],
      countries: [],
      roles: [],
      states: [],
    });
  const isRunEffect = useRef(true);
  const [openFilter, setOpenFilter] = useState(true);
  const [filters, setFilters] = useState<UsersFilterData>({
    city: userGroup?.city ?? "",
    organizations: userGroup?.organizations ?? [],
    statuses: userGroup?.statuses ?? [],
    selectedStates: userGroup?.states ?? [],
    selectedCountries: userGroup?.countries ?? [],
    groups: [],
    roles: userGroup?.roles ?? [],
  });

  const handleApplyFilter = (filterValues: UsersFilterData) => {
    setFilters(filterValues);
    const updatedRows = getFilteredUserRows(users, filterValues);
    setRows(updatedRows);
    //if type is Dynamic then select all rows
    if (groupTypeSwitchValue === UserGroupType.DYNAMIC) {
      setRowSelectionModel(updatedRows.map((user) => user.userId));
    }
    setOpenFilter(false);
  };

  const applyInitialFilter = useCallback(
    (formMasters: UserFormMasterData, groupUsers: User[], allUsers: User[]) => {
      setUserFormMasterData(formMasters);
      const updatedRows = getFilteredUserRows(allUsers, filters);
      setRows(updatedRows);
      setRowSelectionModel(groupUsers.map((user) => user.userId));
      setSelectedUsers(groupUsers);
    },
    [filters]
  );

  const getUserFormMasterData = useCallback(async () => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      const formMasterResponse = await apiClient.get(
        "users/user-form/look-up-data"
      );
      const formMasters = formMasterResponse.data.data as UserFormMasterData;
      const usersResponse = await apiClient.get(`/users`);
      const allUsers = usersResponse.data.data as User[];
      setUsers(allUsers);
      const groupUsers = allUsers.filter((user) =>
        hasMatchingObjects(
          [userGroup?.groupId ?? ""],
          user.groups ?? [],
          "groupId"
        )
      );
      applyInitialFilter(formMasters, groupUsers, allUsers);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  }, [applyInitialFilter, userGroup?.groupId, dispatch]);

  const updateLastAccess = useCallback(async () => {
    await apiClient.put(`users-groups/${userGroup?.groupId}/updateLastAccess`);
  }, [userGroup?.groupId]);

  useEffect(() => {
    if (isRunEffect.current) {
      getUserFormMasterData();
      updateLastAccess();
      isRunEffect.current = false;
    }
  }, [getUserFormMasterData, isRunEffect, updateLastAccess]);

  //if location state has issues then redirect to list page
  useEffect(() => {
    if (!userGroup) {
      dispatch(
        setSnackbarToast({
          message: "Please select group from list",
          open: true,
          severity: "error",
        })
      );
      navigate("/portal/users/groups");
    }
  }, [dispatch, navigate, userGroup]);

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "avatar",
        headerName: "",
        width: 100,
        renderCell: (params) => (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <Avatar
              {...stringAvatar(
                `${params.row.firstName ?? ""} ${params.row.lastName ?? ""}`
              )}
            />
          </Box>
        ),
      },
      { field: "firstName", type: "string", headerName: "First Name", flex: 1 },
      { field: "lastName", type: "string", headerName: "Last Name", flex: 1 },
      { field: "email", type: "string", headerName: "Email", flex: 1 },
      { field: "roleName", type: "string", headerName: "Trust Role", flex: 1 },
      {
        field: "groups",
        type: "string",
        headerName: "Group(s)",
        flex: 1,
        valueGetter: (value) => {
          const groups = (value || []) as UserGroup[];
          return groups.map((obj) => obj.name).join(", ");
        },
      },
      { field: "country", type: "string", headerName: "Country", flex: 1 },
      {
        field: "organization",
        type: "string",
        headerName: "Company/Organization",
        flex: 1,
        valueGetter: (value: any) => {
          return value?.name ?? "";
        },
      },
      {
        field: "status",
        type: "string",
        headerName: "Status",
        width: 60,
        renderCell: (params) => {
          const status = params.row.status || "";
          if (status === UserStatus.ACTIVE) {
            return <CheckCircleOutlineIcon sx={{ color: green[500] }} />;
          } else if (status === UserStatus.INACTIVE) {
            return <BlockIcon sx={{ color: grey[500] }} />;
          } else {
            return status;
          }
        },
      },
    ],
    []
  );

  const handleFilterClick = () => {
    setOpenFilter(true);
  };

  const handleClearFilter = () => {
    setRows(users);
  };

  const handleCancelFilter = () => {
    setOpenFilter(false);
    navigate("/portal/users/groups");
  };

  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [userGroupName, setUserGroupName] = useState("");

  const onSaveGroup = (data: UserGroupNameForm) => {
    if (rowSelectionModel.length > 0) {
      setUserGroupName(data.name);
      setOpenUserTypeDialogue(true);
    } else {
      dispatch(
        setSnackbarToast({
          message: "Please select the users",
          open: true,
          severity: "error",
        })
      );
    }
  };

  const [openUserTypeDialogue, setOpenUserTypeDialogue] = useState(false);

  const onConfirmType = async () => {
    const type = groupTypeSwitchValue;
    const updatedArray = rowSelectionModel as string[];
    const originalArray = selectedUsers.map((user) => user.userId);
    const newAddedUsers = updatedArray.filter(
      (updatedObj) =>
        !originalArray.some((originalObj) => originalObj === updatedObj)
    );
    const removedUsers = originalArray.filter(
      (originalObj) =>
        !updatedArray.some((updatedObj) => updatedObj === originalObj)
    );
    let existingUsers: string[] = [];
    if (userGroup && userGroup.name !== userGroupName)
      existingUsers = originalArray.filter((element) =>
        updatedArray.includes(element)
      );

    const updatedUserGroup: UpdateUserGroup = {
      name: userGroupName,
      type,
      users: rowSelectionModel as string[],
      statuses: filters.statuses,
      roles: filters.roles,
      city: filters.city,
      organizations: filters.organizations,
      countries: filters.selectedCountries,
      states: filters.selectedStates,
      newAddedUsers,
      removedUsers,
      existingUsers,
    };
    //update group
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      const groupResponse = await apiClient.put(
        `/users-groups/${userGroup?.groupId}`,
        updatedUserGroup
      );
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      dispatch(
        setSnackbarToast({
          message: groupResponse.data.meta.message,
          open: true,
          severity: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
    navigate("/portal/users/groups");
  };

  const [groupTypeSwitchValue, setGroupTypeSwitchValue] = React.useState(
    userGroup?.type
  );
  const onCancelGroupType = (type: UserGroupType) => {
    setGroupTypeSwitchValue(type);
  };

  const onConfirmSaveGroupType = (type: UserGroupType) => {
    if (type === UserGroupType.DYNAMIC) {
      const updatedRowsSelection = rows.map((row) => row.userId);
      setRowSelectionModel(updatedRowsSelection);
    }
    setGroupTypeSwitchValue(type);
  };
  const theme = useTheme();
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.contentPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <MuiLink underline="none" color="inherit">
            Users
          </MuiLink>
          <Link
            to="/portal/users/groups"
            style={{
              color: theme.palette.text.secondary,
            }}
          >
            Groups
          </Link>
          <Typography variant="body1">{userGroup?.name}</Typography>
        </Breadcrumbs>
      </div>
      <Card elevation={0} className={classes.contentSection}>
        {
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <AddUserGroup
                isEdit
                onCancelGroup={() => {
                  navigate("/portal/users/groups");
                }}
                onSaveGroup={onSaveGroup}
                formData={{ name: userGroup?.name ?? "" }}
              ></AddUserGroup>
            </Grid>
            <Grid item xs={3} alignContent={"center"}>
              <GroupType
                rows={rows}
                rowSelectionModel={rowSelectionModel as string[]}
                onCancel={onCancelGroupType}
                defaultGroupType={groupTypeSwitchValue as UserGroupType}
                onConfirmSave={onConfirmSaveGroupType}
                filters={filters}
                isFilterSubmitted={true}
              ></GroupType>
            </Grid>
          </Grid>
        }
        {openFilter && (
          <Card elevation={0} className={classes.contentSection}>
            <UsersFilter
              onApplyFilter={handleApplyFilter}
              handleCancelFilter={handleCancelFilter}
              handleClearFilter={handleClearFilter}
              filters={filters}
              isShowCancel={false}
              isResetForm={false}
              userFormMasterData={userFormMasterData}
            />
          </Card>
        )}
        <DataGrid
          columns={columns}
          rows={rows}
          getRowId={(row) => row.userId}
          pageSizeOptions={constants.PAGE_SIZE_OPTIONS}
          initialState={{
            pagination: { paginationModel: constants.PAGINATION_MODEL },
            columns: {
              columnVisibilityModel: {
                email: false,
                groups: false,
                country: false,
                status: false,
              },
            },
          }}
          slots={{
            toolbar: () => (
              <>
                <CustomToolbar handleFilterClick={handleFilterClick} />
                {!openFilter && (
                  <UsersFilterChipDisplay
                    userFormMasterData={userFormMasterData}
                    filters={filters}
                    handleUpdatedFilter={handleApplyFilter}
                    openFilter={() => setOpenFilter(true)}
                  />
                )}
              </>
            ),
            noRowsOverlay: () => (
              <NoRowsOverlay
                hasAccess={hasPermission("users.summary", "read")}
                name="Users"
              />
            ),
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          autoHeight
          sx={{
            marginTop: 1,
            "& .MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "600",
            },
          }}
          checkboxSelection={true}
          isRowSelectable={() => groupTypeSwitchValue === UserGroupType.STATIC}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          disableVirtualization
        />
        {openUserTypeDialogue && (
          <UserGroupTypeDialog
            dialogContent={
              groupTypeSwitchValue === UserGroupType.STATIC
                ? constants.STATIC_GROUP_UPDATE_CONFIRM_MESSAGE
                : constants.DYNAMIC_GROUP_UPDATE_CONFIRM_MESSAGE
            }
            onConfirmType={onConfirmType}
            open={openUserTypeDialogue}
            isEdit={true}
            onCancel={() => {
              setOpenUserTypeDialogue(false);
            }}
          ></UserGroupTypeDialog>
        )}
      </Card>
    </React.Fragment>
  );
};
export default EditUserGroupTable;
