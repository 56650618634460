import LocationOnIcon from "@mui/icons-material/LocationOn";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import PersonIcon from "@mui/icons-material/Person";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import PlaceIcon from "@mui/icons-material/Place";
import HubIcon from "@mui/icons-material/Hub";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import RuleIcon from "@mui/icons-material/Rule";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import KeyIcon from "@mui/icons-material/Key";

import { RouteType } from "./types";
import AccessManagerLayoutPage from "../pages/accessmanager/AccessManagerLayoutPage";
import SitePage from "../pages/sites/sitesList/Sites";
import ThemeSettingsPage from "../pages/settings/ThemeSettings";
import SiteDetails from "pages/sites/siteDetails/SiteDetails";
import DevicesPage from "../pages/devices/devicesList/Devices";
import DevicesGroupsPage from "pages/devices/groups/DeviceGroups";
import DeviceDetails from "pages/devices/deviceDetails/Summary";
import UserGroups from "pages/users/groups/UserGroups";
import Users from "pages/users/Users";
import Rules from "pages/trust/rules/Rules";
import AddRule from "pages/trust/rules/AddRule";
import EditRule from "pages/trust/rules/EditRule";
import PageWrapper from "common/components/layout/PageWrapper";
import { ReactNode } from "react";
import { Route } from "react-router";
import AccountSettings from "pages/settings/AccountSettings/AccountSettings";
import EditDeviceGroupTable from "pages/devices/devicesList/EditDeviceGroupTable";
import EditUserGroupTable from "pages/users/EditUserGroupTable";
import { constants } from "common/constants";
import ResourcesPage from "pages/settings/Resources/Resources";
import GenerateApiKey from "pages/settings/GenerateApiKey";
import { isEndUser } from "common/helpers/utils";
import HttpsConnections from "pages/settings/HttpsConnection/HttpsConnections";
// import FeatureSettings from 'pages/settings/FeaturesSettings/FeatureSettings';

export const getAppRoutes = () => {
  return isEndUser()
    ? [
        {
          path: "/portal/sites/summary",
          element: <SitePage />,
          state: "sites.summary",
          sidebarProps: {
            displayText: "Sites",
            icon: <LocationOnIcon />,
          },
        },
        {
          path: "/portal/sites/summary/:siteId",
          element: <SiteDetails />,
          state: "sites.summary",
        },
        {
          path: "/portal/devices/summary",
          element: <DevicesPage />,
          state: "devices.summary",
          sidebarProps: {
            displayText: "Devices",
            icon: <DeviceHubIcon />,
          },
        },
      ]
    : [
        {
          path: "/portal/sites",
          element: <AccessManagerLayoutPage />,
          state: "sites",
          sidebarProps: {
            displayText: "Sites",
            icon: <PlaceIcon />,
          },
          child: [
            {
              isSubMenu: true,
              path: "/portal/sites/summary",
              element: <SitePage />,
              state: "sites.summary",
              sidebarProps: {
                displayText: "List",
                popoverDisplayText: "Sites List",
                icon: <LocationOnIcon />,
              },
            },
            {
              path: "/portal/sites/summary/:siteId",
              element: <SiteDetails />,
              state: "sites.summary",
            },
          ],
        },
        {
          path: "/portal/devices",
          element: <AccessManagerLayoutPage />,
          state: "devices",
          sidebarProps: {
            displayText: "Devices",
            icon: <DeviceHubIcon />,
          },
          child: [
            {
              isSubMenu: true,
              path: "/portal/devices/summary",
              element: <DevicesPage />,
              state: "devices.summary",
              sidebarProps: {
                displayText: "List",
                popoverDisplayText: "Devices List",
                icon: <DeviceHubIcon />,
              },
            },
            {
              isSubMenu: true,
              path: "/portal/devices/groups",
              element: <DevicesGroupsPage />,
              state: "devices.groups",
              sidebarProps: {
                displayText: "Groups",
                popoverDisplayText: "Devices Groups",
                icon: <HubIcon />,
              },
            },
            {
              path: "/portal/devices/summary/:deviceId",
              element: <DeviceDetails />,
              state: "devices.summary",
            },
            {
              path: "/portal/devices/groups/:groupId",
              element: <EditDeviceGroupTable />,
              state: "devices.groups",
            },
          ],
        },
        {
          path: "/portal/users",
          element: <AccessManagerLayoutPage />,
          state: "users",
          sidebarProps: {
            displayText: "Users",
            icon: <PersonIcon />,
          },
          child: [
            {
              isSubMenu: true,
              path: "/portal/users/summary",
              element: <Users />,
              state: "users.summary",
              sidebarProps: {
                displayText: "List",
                popoverDisplayText: "Users List",
                icon: <PersonIcon />,
              },
            },
            {
              isSubMenu: true,
              path: "/portal/users/groups",
              element: <UserGroups />,
              state: "users.groups",
              sidebarProps: {
                displayText: "Groups",
                popoverDisplayText: "Users Groups",
                icon: <PeopleIcon />,
              },
            },
            {
              path: "/portal/users/groups/:groupId",
              element: <EditUserGroupTable />,
              state: "users.groups",
            },
          ],
        },
        {
          path: "/portal/trust",
          element: <AccessManagerLayoutPage />,
          state: "trust",
          sidebarProps: {
            displayText: "Trust",
            icon: <VerifiedUserIcon />,
          },
          child: [
            {
              isSubMenu: true,
              path: "/portal/trust/list",
              element: <Rules />,
              state: "trust.list",
              sidebarProps: {
                displayText: "List",
                popoverDisplayText: "Trust List",
                icon: <RuleIcon />,
              },
            },
            {
              path: "/portal/trust/list/new",
              element: <AddRule />,
              state: "trust.list",
            },
            {
              path: "/portal/trust/list/edit",
              element: <EditRule />,
              state: "trust.list",
            },
          ],
        },
        {
          path: "/portal/settings",
          element: <AccessManagerLayoutPage />,
          state: constants.SETTINGS_ROUTE_STATE,
          sidebarProps: {
            displayText: "Settings",
            icon: <SettingsIcon />,
          },
          child: [
            {
              isSubMenu: true,
              path: "/portal/settings/theme",
              element: <ThemeSettingsPage />,
              state: "settings.theme",
              sidebarProps: {
                displayText: "Theme",
                popoverDisplayText: "Theme Settings",
                icon: <ColorLensIcon />,
              },
            },
            {
              path: "/portal/settings/account",
              element: <AccountSettings />,
              state: "settings.account",
            },
            {
              isSubMenu: true,
              path: "/portal/settings/resources",
              element: <ResourcesPage />,
              state: "settings.resources",
              sidebarProps: {
                displayText: "Resources",
                popoverDisplayText: "Resources Settings",
                icon: <ViewModuleIcon />,
              },
            },
            {
              isSubMenu: true,
              path: "/portal/settings/apiKey",
              element: <GenerateApiKey />,
              state: "settings.apiKey",
              sidebarProps: {
                displayText: "API key",
                popoverDisplayText: "API key Settings",
                icon: <KeyIcon />,
              },
            },
            {
              isSubMenu: true,
              path: "/portal/settings/httpsSettings",
              element: <HttpsConnections />,
              state: "settings.httpsSettings",
              sidebarProps: {
                displayText: "Http/s Connection",
                popoverDisplayText: "Http/s Connection",
                icon: <KeyIcon />,
              },
            },
            // {
            //   isSubMenu: true,
            //   path: '/portal/settings/featureSettings',
            //   element: <FeatureSettings />,
            //   state: 'settings.featureSettings',
            //   sidebarProps: {
            //     displayText: 'feature Settings',
            //     popoverDisplayText: 'featureSettings',
            //     icon: <KeyIcon />,
            //   },
            // },
          ],
        },
      ];
};

export const generateRoute = (routes: RouteType[]): ReactNode => {
  return (
    <>
      {routes.map((route, index) =>
        route.index ? (
          <Route
            index
            path={route.path}
            element={
              <PageWrapper state={route.state}>{route.element}</PageWrapper>
            }
            key={`route-${index}-${route.state}`}
          />
        ) : (
          <Route
            path={route.path}
            element={
              <PageWrapper state={route.child ? undefined : route.state}>
                {route.element}
              </PageWrapper>
            }
            key={`route-${index}-${route.state}`}
          >
            {route.child && generateRoute(route.child)}
          </Route>
        )
      )}
    </>
  );
};
