import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CssBaseline, Breadcrumbs, Typography, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";

import { Site } from "../types";
import SiteSummaryTab from "./Summary";
import apiClient from "common/apiClientAxios";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import { useContentStyles } from "common/styles/useContentStyles";
import { siteDefaultValues } from "../sitesList/Sites";
import { constants } from "common/constants";

const SiteDetails: React.FC = () => {
  const classes = useContentStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { site } = (location.state as { site: Site }) || {
    site: { ...siteDefaultValues },
  };
  const [siteData, setSiteData] = React.useState<Site>(site);
  //if location state has issues then redirect to list page
  useEffect(() => {
    if (!site?.siteId) {
      dispatch(
        setSnackbarToast({
          message: "Please select site from list",
          open: true,
          severity: "error",
        })
      );
      navigate("/portal/sites/summary");
    }
  }, [dispatch, navigate, site]);

  const onGetSiteDetails = React.useCallback(async () => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      const siteResponse = await apiClient.get(`/sites/${site?.siteId}`);
      setSiteData(siteResponse.data.data as Site);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      //Get devices master data
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  }, [dispatch, site?.siteId]);

  const handleFavoriteSite = async (siteObj: Site) => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      if (siteObj.isFavorite) {
        await apiClient.delete(`users/favoriteSite/${siteObj.siteId}`);
      } else {
        await apiClient.put(`users/favoriteSite/${siteObj.siteId}`);
      }
      const siteResponse = await apiClient.get(`/sites/${siteObj?.siteId}`);
      setSiteData(siteResponse.data.data as Site);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  };
  const theme = useTheme();
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.contentPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            to="/portal/sites/summary"
            style={{
              color: theme.palette.text.secondary,
            }}
          >
            Sites
          </Link>
          <Typography variant="body1">{siteData?.name}</Typography>
        </Breadcrumbs>
      </div>
      <SiteSummaryTab
        site={siteData}
        onGetUpdatedSite={onGetSiteDetails}
        handleFavoriteSite={handleFavoriteSite}
      ></SiteSummaryTab>
    </React.Fragment>
  );
};

export default SiteDetails;
