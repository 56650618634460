import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

import { Site } from "../types";

type ResetSiteConnectionProps = {
  selectedSite: Site;
  open: boolean;
  onCancelReset: () => void;
  onResetSite: () => void;
};

const ResetSiteConnection: React.FC<ResetSiteConnectionProps> = (props) => {
  const onResetSite = () => {
    props.onResetSite();
  };

  return (
    <React.Fragment>
      <Dialog open={props.open}>
        <DialogTitle>
          {`Reset site '${props.selectedSite.name}' connections?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {"This will update the in-use and available ports for site."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onCancelReset} color="info" variant="outlined">
            {"Cancel"}
          </Button>
          <Button
            onClick={onResetSite}
            color="warning"
            autoFocus
            variant="contained"
          >
            {"Reset"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default ResetSiteConnection;
