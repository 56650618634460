import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import KeyboardArrowDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  TableCell,
  TableRow,
  Table,
  TableBody,
  TableContainer,
  Collapse,
  TableHead,
  Box,
  Grid,
  TextField,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
  TablePagination,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
} from "@mui/material";
import { MoreVert, Search as SearchIcon } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { useNavigate } from "react-router-dom";
import GroupDevicesSubTable from "./GroupDevicesSubTable";
import NoRowsOverlay from "common/components/NoRowsOverlay";
import { FilterDisplayOption, RemoteAccessType } from "common/enums";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import apiClient from "common/apiClientAxios";
import { constants } from "common/constants";
import {
  getAccessWindowDisplaySetting,
  hasPermission,
  sortRows,
} from "common/helpers/utils";
import { Device, DeviceGroup } from "../types";
import { useContentStyles } from "common/styles/useContentStyles";
import { RootState } from "redux/Store";

type DeviceGroupsTableProps = {
  data: DeviceGroup[];
  handleDeleteGroup: (group: DeviceGroup) => void;
  handleFavoriteGroup: (group: DeviceGroup) => void;
  remoteAccessType?: RemoteAccessType;
};

const DeviceGroupsTable: React.FC<DeviceGroupsTableProps> = (props) => {
  const classes = useContentStyles();
  const [rows, setRows] = useState<DeviceGroup[]>([]);
  const [filteredRows, setFilteredRows] = useState<DeviceGroup[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [selectedSortOption, setSelectedSortOption] = useState(
    FilterDisplayOption.ALL
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (inputValue: string) => {
    const searchedRows = props.data.filter((row) =>
      row.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredRows(searchedRows);
    const records = sortRows([...searchedRows], selectedSortOption);
    setRows(records as DeviceGroup[]);
    setPage(0);
  };

  const handleOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setSelectedSortOption(value as FilterDisplayOption);
    const records = sortRows([...filteredRows], value as FilterDisplayOption);
    setRows(records as DeviceGroup[]);
  };

  const { userData } = useSelector((state: RootState) => state.userState);
  useEffect(() => {
    setSelectedSortOption(FilterDisplayOption.ALL);
    setRows(props.data);
    setFilteredRows(props.data);
  }, [props.data, userData.accessWindowDisplay]);

  const Row: React.FC<{ row: DeviceGroup }> = ({ row }) => {
    const [open, setOpen] = useState(false);
    const [devices, setDevices] = useState<Device[]>([]);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const getGroupDevices = async () => {
      if (!open) {
        try {
          dispatch(
            setLoader({
              loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
              openLoader: true,
            })
          );
          const api = `/devices?groupId=${row.groupId}`;
          const devicesResponse = await apiClient.get(api);
          setDevices(devicesResponse.data.data as Device[]);
          dispatch(
            setLoader({
              loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
              openLoader: false,
            })
          );
        } catch (error: any) {
          dispatch(
            setLoader({
              loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
              openLoader: false,
            })
          );
          const errorData =
            error.response?.data?.meta?.message || String(error.message);
          dispatch(
            setSnackbarToast({
              message: errorData,
              open: true,
              severity: "error",
            })
          );
        }
      }
      setOpen(!open);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      event.preventDefault();
      event.stopPropagation();
    };

    const handleFavoriteClick = (group: DeviceGroup) => {
      props.handleFavoriteGroup(group);
    };

    const displaySettings = getAccessWindowDisplaySetting();

    return (
      <>
        <TableRow
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
            td: { borderBottom: 0 },
          }}
        >
          <TableCell padding="none">
            {hasPermission("devices.groups", "write") ? (
              <>
                <IconButton size="small" onClick={() => getGroupDevices()}>
                  {open ? (
                    <KeyboardArrowDownIcon fontSize="large" />
                  ) : (
                    <KeyboardArrowRightIcon fontSize="large" />
                  )}
                </IconButton>
                <IconButton onClick={() => handleFavoriteClick(row)}>
                  {row.isFavorite ? (
                    <StarIcon color="secondary" />
                  ) : (
                    <StarOutlineIcon />
                  )}
                </IconButton>
                <Link
                  component="button"
                  sx={{
                    color: (theme) => theme.palette.info.main,
                    textDecorationColor: (theme) => theme.palette.info.main,
                  }}
                  onClick={() => {
                    navigate(`/portal/devices/groups/${row.groupId}`, {
                      state: {
                        deviceGroup: row,
                      },
                    });
                  }}
                >
                  {row.name}
                </Link>
              </>
            ) : (
              <>
                <IconButton size="small" onClick={() => getGroupDevices()}>
                  {open ? (
                    <KeyboardArrowDownIcon fontSize="large" />
                  ) : (
                    <KeyboardArrowRightIcon fontSize="large" />
                  )}
                </IconButton>
                <IconButton onClick={() => handleFavoriteClick(row)}>
                  {row.isFavorite ? (
                    <StarIcon color="secondary" />
                  ) : (
                    <StarOutlineIcon />
                  )}
                </IconButton>
                {row.name}
              </>
            )}
          </TableCell>
          <TableCell padding="none">
            {dayjs(row.modifiedOn).tz(displaySettings.zone).format("LT L")}
          </TableCell>
          <TableCell padding="none" align="center">
            {row.devicesCount}
          </TableCell>
          <TableCell size="small" padding="none" align="center">
            <>
              <IconButton
                id={`menu-button-${row.groupId}`}
                onClick={(event) => handleClick(event)}
              >
                <MoreVert />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={anchorEl?.id === `menu-button-${row.groupId}`}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem
                  key={"editGroup"}
                  disabled={!hasPermission("devices.groups", "write")}
                  onClick={() => {
                    navigate(`/portal/devices/groups/${row.groupId}`, {
                      state: {
                        deviceGroup: row,
                      },
                    });
                  }}
                >
                  <ListItemIcon>
                    <EditIcon color="info" />
                  </ListItemIcon>
                  <Typography variant="body2">{"Edit"}</Typography>
                </MenuItem>
                <MenuItem
                  key={"deleteGroup"}
                  disabled={!hasPermission("devices.groups", "delete")}
                  onClick={() => {
                    props.handleDeleteGroup(row);
                  }}
                >
                  <ListItemIcon>
                    <DeleteIcon color="info" />
                  </ListItemIcon>
                  <Typography variant="body2">{"Delete"}</Typography>
                </MenuItem>
              </Menu>
            </>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <GroupDevicesSubTable
                  data={devices}
                  remoteAccessType={props.remoteAccessType}
                ></GroupDevicesSubTable>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item>
          <TextField
            placeholder="Search group name"
            variant="outlined"
            size="small"
            fullWidth
            onChange={(event) => handleFilterChange(event.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton edge="end">
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
        <RadioGroup
          row
          name="filter-options"
          value={selectedSortOption}
          onChange={handleOptionChange}
          sx={{ color: (theme) => theme.palette.info.main }}
        >
          <FormControlLabel
            value={FilterDisplayOption.FAVORITE}
            control={
              <Radio
                size="small"
                sx={{ color: (theme) => theme.palette.info.main }}
              />
            }
            label={<Typography variant="body2">{"Favorites"}</Typography>}
          />
          <FormControlLabel
            value={FilterDisplayOption.RECENT}
            control={
              <Radio
                size="small"
                sx={{ color: (theme) => theme.palette.info.main }}
              />
            }
            label={<Typography variant="body2">{"Recent"}</Typography>}
          />
          <FormControlLabel
            value={FilterDisplayOption.ALL}
            control={
              <Radio
                size="small"
                sx={{ color: (theme) => theme.palette.info.main }}
              />
            }
            label={<Typography variant="body2">{"All"}</Typography>}
          />
        </RadioGroup>
      </Grid>
      <TableContainer className={classes.borderedTableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Name</strong>
              </TableCell>
              <TableCell>
                <strong>Date updated</strong>
              </TableCell>
              <TableCell align="center">
                <strong># Devices</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Actions</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <NoRowsOverlay
                    hasAccess={hasPermission("devices.groups", "read")}
                    name="Devices Groups"
                  />
                </TableCell>
              </TableRow>
            ) : (
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => <Row key={row.groupId} row={row} />)
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={constants.PAGE_SIZE_OPTIONS}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default DeviceGroupsTable;
