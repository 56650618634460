import { FC } from "react";

import { TextField, TextFieldProps } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";

type FormInputTextProps = {
  name: string;
  endAdornment?: any;
  defaultValue?: string | number | null;
} & TextFieldProps;

const FormInputText: FC<FormInputTextProps> = ({ name, ...otherProps }) => {
  // Utilizing useFormContext to have access to the form Context
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={otherProps.defaultValue ?? ""}
      render={({ field }) => (
        <TextField
          {...field}
          {...otherProps}
          variant="outlined"
          sx={{ m: "0.8rem" }}
          error={!!errors[name]}
          helperText={
            errors[name] ? (errors[name]?.message as unknown as string) : ""
          }
        />
      )}
    />
  );
};

export default FormInputText;
