import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Typography from "@mui/material/Typography";
import { Controller, useFormContext } from "react-hook-form";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "2px dashed #ccc",
    borderRadius: "5px",
    padding: "20px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f9f9f9",
    },
  },
  icon: {
    fontSize: "48px",
    marginBottom: "10px",
  },
}));

type FileUploadProps = {
  control: any;
  name: string;
  rules?: any;
  onFileUpload: (file: File) => void;
};

const FileUpload: React.FC<FileUploadProps> = ({
  control,
  name,
  rules,
  onFileUpload,
}) => {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { getValues } = useFormContext();

  const handleFileChange =
    (onChange: (fileName: string) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const fileList = event.target.files;
      if (fileList && fileList.length > 0) {
        setSelectedFile(fileList[0]);
        onFileUpload(fileList[0]);
        onChange(fileList[0].name);
      }
    };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop =
    (onChange: (fileName: string) => void) =>
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const fileList = event.dataTransfer.files;
      if (fileList && fileList.length > 0) {
        setSelectedFile(fileList[0]);
        onFileUpload(fileList[0]);
        onChange(fileList[0].name);
      }
    };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <div
          className={classes.root}
          onDragOver={handleDragOver}
          onDrop={handleDrop(onChange)}
        >
          <CloudUploadIcon className={classes.icon} />
          <input
            type="file"
            id="fileInput"
            data-testid="fileInput"
            style={{ display: "none" }}
            onChange={handleFileChange(onChange)}
          />
          <label htmlFor="fileInput">
            <Button variant="outlined" color="primary" component="span">
              Choose File
            </Button>
          </label>
          {selectedFile ? (
            <div>
              <strong>Selected File:</strong> {selectedFile.name}
            </div>
          ) : (
            getValues(name) && (
              <div>
                <strong>Selected File:</strong> {getValues(name)}
              </div>
            )
          )}

          {error && <Typography color="error">{error.message}</Typography>}
        </div>
      )}
    />
  );
};

export default FileUpload;
