import { FC, useState } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { RoleRouteFeature } from "pages/settings/types";

type MenuSettingsProps = {
  permissions?: RoleRouteFeature[];
};

const MenuSettings: FC<MenuSettingsProps> = ({ permissions }) => {
  const { control, getValues, setValue } = useFormContext();

  const [selectAllMenus, setSelectAllMenus] = useState(false);

  const handleSelectAllMenus = (checked: boolean) => {
    setSelectAllMenus(checked);
    const allRead = getValues("routePermissions")?.map(
      (item: RoleRouteFeature) => ({
        ...item,
        hasAccess: checked,
      })
    );
    setValue("routePermissions", allRead, { shouldDirty: true });
  };

  return (
    <TableContainer sx={{ maxHeight: 400 }}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Menu Name</strong>
            </TableCell>
            <TableCell padding="none">
              <strong>Access</strong>
              <br />
              <Checkbox
                checked={selectAllMenus}
                onChange={(_event, checked) => handleSelectAllMenus(checked)}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {permissions?.map((feature, index) => (
            <TableRow key={feature.featureId}>
              <TableCell>{feature.featureName}</TableCell>
              <TableCell padding="none">
                <Controller
                  name={`routePermissions.${index}.hasAccess` as any}
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      data-testid={`routePermissions.${index}.hasAccess`}
                    />
                  )}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MenuSettings;
