import React, { useCallback, useRef, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Guacamole from "guacamole-common-js";
import { DeviceDetails } from "pages/devices/types";
import apiClient from "common/apiClientAxios";
import { DeviceAccess } from "common/types";
import { useDispatch } from "react-redux";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import { deviceAccessErrors } from "pages/devices/device-access-errors";
import { constants } from "common/constants";
import {
  getGateEndTimeAndCountDownEndTime,
  getLoggedInUser,
  isAdminUser,
  isEndUser,
} from "common/helpers/utils";
import { Protocol } from "common/enums";
import CountdownDialog from "common/components/CountDownDialog";
import AdminAccessCountdown from "./AdminAccessCountDown";
// import useNotifications from './useNotifications';

interface RemoteAccessSSHProps {
  open: boolean;
  onClose: (gateId?: number) => void;
  device: DeviceDetails;
  isHideDialog?: boolean;
}

const RemoteAccessSSH: React.FC<RemoteAccessSSHProps> = ({
  open,
  onClose,
  device,
  isHideDialog = false,
}) => {
  const isRunEffect = useRef(true);
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [guacamoleClient, setGuacamoleClient] = useState<Guacamole.Client>();
  const [guacamoleKeyboard] = useState<Guacamole.Keyboard>(
    new Guacamole.Keyboard(document)
  );
  const [guacamoleClientState, setGuacamoleClientState] = useState(-1);
  const [guacamoleTunnelState, setGuacamoleTunnelState] = useState(-1);
  const [errorMessage, setErrorMessage] = useState("");
  const accessEndTime = getGateEndTimeAndCountDownEndTime(
    device.accessProfile?.accessEndTime ?? 0
  );
  const [countDownOpen, setCountDownOpen] = useState<boolean>(false);
  const [accessSessionId, setAccessSessionId] = useState(null);
  const dispatch = useDispatch();

  const onReconnectSSH = async () => {
    if (device.accessProfile) {
      await getToken({
        connectionProfile: device.accessProfile,
        type: Protocol.SSH,
        endTime: accessEndTime.endTime,
        siteId: device.siteId,
        siteName: device.siteName,
      });
    }
  };

  const closeConnection = useCallback(
    async (gateId: number, inboundPort: number, inAccessSessionId?: string) => {
      console.log(
        `Closing connection with Gate id:${gateId}, inboundPort: ${inboundPort}`
      );
      try {
        dispatch(
          setLoader({
            loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
            openLoader: true,
          })
        );
        await apiClient.post(`devices/${device.deviceId}/closeConnection`, {
          siteId: device.siteId,
          gateId: gateId,
          inboundPort: inboundPort,
          type: Protocol.SSH,
          accessSessionId: inAccessSessionId,
        });
        setAccessSessionId(null);
        dispatch(
          setLoader({
            loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
            openLoader: false,
          })
        );
        console.log(`Connection closed successfully with Gate id:${gateId}`);
      } catch (error: any) {
        dispatch(
          setLoader({
            loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
            openLoader: false,
          })
        );
        const errorData =
          error.response?.data?.meta?.message || String(error.message);
        dispatch(
          setSnackbarToast({
            message: errorData,
            open: true,
            severity: "error",
          })
        );
      }
    },
    [device.deviceId, device.siteId, dispatch]
  );

  const disconnectGuacamoleClient = useCallback(() => {
    setAccessSessionId(null);
    setErrorMessage("Device is disconnected...");
    if (guacamoleKeyboard) {
      guacamoleKeyboard.onkeyup = null;
      guacamoleKeyboard.onkeydown = null;
    }
    guacamoleClientState === Guacamole.Client.State.CONNECTED &&
      guacamoleClient?.disconnect();
  }, [guacamoleClient, guacamoleClientState, guacamoleKeyboard]);

  const onDisconnect = () => {
    console.log(`Disconnecting device`);
    disconnectGuacamoleClient();
    window.setTimeout(() => onClose(), 100);
  };

  const disconnectFromCanvas = useCallback(
    async (inGateId: number, inInboundPort: number) => {
      await closeConnection(inGateId, inInboundPort);
      window.setTimeout(() => onClose(), 100);
    },
    [closeConnection, onClose]
  );

  const connectToDevice = useCallback(
    (
      token: string,
      inGateId: number,
      inInboundPort: number,
      webSocketBaseURL: string,
      inAccessSessionId?: string
    ) => {
      window.setTimeout(() => {
        const display = document.getElementById("SSHdisplay");
        const container = document.getElementById("container");
        const width = container?.clientWidth
          ? container?.clientWidth - constants.CANVAS_WIDTH_OFFSET
          : constants.DIALOG_CANVAS_WIDTH;
        const height = container?.clientHeight
          ? container?.clientHeight - constants.CANVAS_HEIGHT_OFFSET
          : constants.DIALOG_CANVAS_HEIGHT;
        let clientConnectedState = -1;
        if (display && token) {
          const guacTunnel = new Guacamole.WebSocketTunnel(
            `${webSocketBaseURL}/?token=${token}&width=${width}&height=${height}&`
          );
          const guac = new Guacamole.Client(guacTunnel);
          setGuacamoleClient(guac);
          const displayElement = guac.getDisplay().getElement();
          display?.appendChild(displayElement);

          // Mouse
          const mouse = new Guacamole.Mouse(guac.getDisplay().getElement());
          mouse.onEach(["mousedown", "mousemove", "mouseup"], () => {
            guac.sendMouseState(mouse.currentState);
          });
          // Keyboard
          if (guacamoleKeyboard) {
            guacamoleKeyboard.onkeydown = function (keysym) {
              guac.sendKeyEvent(1, keysym);
            };
            guacamoleKeyboard.onkeyup = function (keysym) {
              guac.sendKeyEvent(0, keysym);
            };
          }
          let isConnectionClosed = false;
          guac.onstatechange = async (state: Guacamole.Client.State) => {
            console.log("Guacamole Client State =", state);
            if (state === 3) {
              clientConnectedState = 3;
              setCountDownOpen(true);
            }
            //if client connection closed and canvas is displayed then remove canvas and close dialog
            if (state === 5) {
              console.log("Guacamole Client Disconnected");
              if (clientConnectedState === 3) {
                window.setTimeout(async () => {
                  if (guacamoleKeyboard) {
                    guacamoleKeyboard.onkeyup = null;
                    guacamoleKeyboard.onkeydown = null;
                    if (!isConnectionClosed) {
                      isConnectionClosed = true;
                      await disconnectFromCanvas(inGateId, inInboundPort);
                    }
                  }
                }, 200);
              }
            } else {
              setGuacamoleClientState(state);
            }
          };
          guacTunnel.onstatechange = (state: Guacamole.Tunnel.State) => {
            console.log("Guacamole Tunnel State =", state);
            setGuacamoleTunnelState(state);
            if (state === 2) {
              console.log("Guacamole Tunnel Disconnected");
              handleDisconnect();
            }
          };
          const handleDisconnect = async () => {
            if (display.hasChildNodes()) display.replaceChildren();
            if (guacamoleClientState === Guacamole.Client.State.CONNECTED)
              guac?.disconnect();
            if (guacamoleKeyboard) {
              guacamoleKeyboard.onkeyup = null;
              guacamoleKeyboard.onkeydown = null;
            }
            //close connection
            if (!isConnectionClosed) {
              isConnectionClosed = true;
              closeConnection(inGateId, inInboundPort, inAccessSessionId);
            }
          };
          const handleError = (error: Guacamole.Status) => {
            console.log("Error while connecting...");
            console.error(
              deviceAccessErrors[error.code] ?? "Something went wrong..."
            );
            clientConnectedState = -1;
            setErrorMessage(
              deviceAccessErrors[error.code] ?? "Something went wrong..."
            );
          };
          // Error handler
          guac.onerror = function (error) {
            handleError(error);
          };
          guacTunnel.onerror = function (error) {
            handleError(error);
          };
          // Connect
          guac.connect(" ");
        }
      }, 1000);
    },
    [
      closeConnection,
      disconnectFromCanvas,
      guacamoleClientState,
      guacamoleKeyboard,
    ]
  );

  const getToken = useCallback(
    async (connectionSettings: DeviceAccess) => {
      try {
        setErrorMessage("");
        dispatch(
          setLoader({
            loaderMessage: "Please wait connecting...",
            openLoader: true,
          })
        );
        const user = getLoggedInUser();
        const apiUrl = `devices/${device.deviceId}/getToken`;
        const tokenResponse = await apiClient.post(apiUrl, {
          ...connectionSettings,
          userId: user.userId,
          loginSessionId: user.loginSessionId,
        });
        const tokenResponseData = tokenResponse.data.data;
        const token = tokenResponseData.token;
        setAccessSessionId(tokenResponseData.accessSessionId);
        setOpenDialog(true);
        dispatch(
          setLoader({
            loaderMessage: "Please wait connecting...",
            openLoader: false,
          })
        );
        //connect to guacamole server
        const webSocketBaseURL =
          process.env.REACT_APP_USE_LOCAL_WEB_SOCKET_URL?.toLowerCase() ===
          "yes"
            ? process.env.REACT_APP_WEB_SOCKET_URL ??
              constants.DEFAULT_WEB_SOCKET_URL
            : `${process.env.REACT_APP_WEB_SOCKET_PROTOCOL ?? "ws"}://${
                connectionSettings.siteName
              }-rc:${constants.DEFAULT_WEB_SOCKET_PORT}`;
        connectToDevice(
          token,
          tokenResponseData.gateId ?? -1,
          tokenResponseData.inboundPort ?? -1,
          webSocketBaseURL,
          tokenResponseData.accessSessionId
        );
        return token;
      } catch (error: any) {
        dispatch(
          setLoader({
            loaderMessage: "Please wait connecting...",
            openLoader: false,
          })
        );
        const errorData =
          error.response?.data?.meta?.message || String(error.message);
        setErrorMessage(errorData);
        dispatch(
          setSnackbarToast({
            message: errorData,
            open: true,
            severity: "error",
          })
        );
        //if dialog then close
        if (!isHideDialog) {
          onClose();
        }
      }
    },
    [connectToDevice, device.deviceId, dispatch, isHideDialog, onClose]
  );

  React.useEffect(() => {
    if (isRunEffect.current) {
      isRunEffect.current = false;
      if (device.accessProfile) {
        getToken({
          connectionProfile: device.accessProfile,
          type: Protocol.SSH,
          endTime: accessEndTime.endTime,
          siteId: device.siteId,
          siteName: device.siteName,
        });
      }
    }
  }, [
    accessEndTime,
    device.accessProfile,
    device.siteId,
    getToken,
    device.siteName,
  ]);

  React.useEffect(() => {
    let isDisconnecting = false;
    // Track if the client has fully disconnected
    const waitForClientDisconnect = () => {
      return new Promise<void>((resolve) => {
        // Poll until disconnected
        const interval = setInterval(() => {
          if (guacamoleClientState === Guacamole.Client.State.DISCONNECTED) {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      });
    };
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = ""; // Standard message to trigger the prompt
    };

    const handleUnload = async () => {
      try {
        if (guacamoleKeyboard) {
          guacamoleKeyboard.onkeyup = null;
          guacamoleKeyboard.onkeydown = null;
        }
        // If the client is connected, disconnect and wait for confirmation
        if (
          guacamoleClientState === Guacamole.Client.State.CONNECTED &&
          !isDisconnecting
        ) {
          isDisconnecting = true;
          guacamoleClient?.disconnect();
          // Wait for the client to fully disconnect
          await waitForClientDisconnect();
          await new Promise((resolve) => setTimeout(resolve, 2000));
        }
      } catch (error) {
        console.error("Error while handling unload process:", error);
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, [guacamoleClient, guacamoleClientState, guacamoleKeyboard]);

  const handleCloseCountDownPopup = () => {
    setCountDownOpen(false);
  };

  const handleTimeUp = () => {
    setCountDownOpen(true);
  };

  // const updateLastDeviceAccess = useCallback(async () => {
  //   if (accessSessionId && document.visibilityState === 'visible') {
  //     console.log(`keep alive call...`);
  //     try {
  //       await apiClient.put(`devices/accessSessions/${accessSessionId}`);
  //     } catch (error: any) {
  //       const errorData =
  //         error.response?.data?.meta?.message || String(error.message);
  //       dispatch(
  //         setSnackbarToast({
  //           message: errorData,
  //           open: true,
  //           severity: 'error',
  //         }),
  //       );
  //     }
  //   }
  // }, [accessSessionId, dispatch]);

  // React.useEffect(() => {
  //   const intervalId = setInterval(
  //     updateLastDeviceAccess,
  //     process.env.REACT_APP_KEEP_ALIVE_INTERVAL
  //       ? +process.env.REACT_APP_KEEP_ALIVE_INTERVAL * 1000
  //       : constants.KEEP_ALIVE_INTERVAL * 1000,
  //   );
  //   return () => clearInterval(intervalId);
  // }, [updateLastDeviceAccess]);

  React.useEffect(() => {
    const worker = new Worker(new URL("./keepAliveWorker.js", import.meta.url));
    //Web Workers operate in an isolated context and don’t have access to the variables, functions, or libraries in the main thread
    //So pass accessToken, baseURL, interval in function call
    const accessToken =
      sessionStorage.getItem("access_token") &&
      localStorage.getItem("access_token")
        ? sessionStorage.getItem("access_token")
        : localStorage.getItem("access_token");
    const baseURL =
      process.env.REACT_APP_API_BASE_URL ?? "http://localhost:3001/api/v1/";
    if (accessSessionId) {
      // Start the worker and pass the API endpoint
      worker.postMessage({
        type: "start",
        url: `${baseURL}devices/accessSessions/${accessSessionId}`,
        token: accessToken,
        interval: process.env.REACT_APP_KEEP_ALIVE_INTERVAL
          ? +process.env.REACT_APP_KEEP_ALIVE_INTERVAL * 1000
          : constants.KEEP_ALIVE_INTERVAL * 1000,
      });
      worker.onmessage = (event) => {
        if (event.data.type === "error") {
          disconnectGuacamoleClient();
        }
      };
    }

    return () => {
      // Stop the worker on component unmount
      worker.postMessage({ type: "stop" });
      worker.terminate();
    };
  }, [accessSessionId, disconnectGuacamoleClient]);

  // const handleNotification = (message: string) => {
  //   if (message === accessSessionId) {
  //     setAccessSessionId(null);
  //     setErrorMessage('Device is disconnected...');
  //     disconnectGuacamoleClient();
  //   }
  // };
  // useNotifications(handleNotification);

  return isHideDialog ? (
    <Card sx={{ overflow: "auto", minHeight: "100vh" }}>
      <CardHeader
        sx={{ paddingTop: 1, paddingBottom: 0 }}
        titleTypographyProps={{ textAlign: "center" }}
        title={
          <>
            <Typography component="span" sx={{ fontSize: 16 }}>
              {`Connect to device: '${device.name}' IP: ${device.accessProfile?.deviceIP} using ${device.accessProfile?.name} account`}
            </Typography>
            <br />
            {isAdminUser() ? (
              <AdminAccessCountdown deviceTimezone={device.timezone} />
            ) : (
              <CountdownDialog
                open={false}
                countDownTime={accessEndTime.countDownTime}
                handleDisconnectOkayClick={onDisconnect}
                onClose={handleCloseCountDownPopup}
                deviceTimezone={device.timezone}
                onTimeUp={handleTimeUp}
                isShowOnlyCountdown={guacamoleClientState === 3}
              ></CountdownDialog>
            )}
          </>
        }
        action={
          <Button
            size="small"
            variant="contained"
            color="info"
            onClick={onDisconnect}
          >
            {"Close"}
          </Button>
        }
      />
      <CardContent sx={{ minHeight: "90vh" }} id="container">
        <Grid container justifyContent="center" alignItems="center">
          {/* Loading State */}
          {(guacamoleTunnelState === 0 ||
            guacamoleClientState === 1 ||
            guacamoleClientState === 2) &&
            guacamoleTunnelState !== 2 && (
              <>
                <Grid item>
                  <CircularProgress />
                </Grid>
                <Grid item>
                  <div>Connecting...</div>
                </Grid>
              </>
            )}
          {/* Error State */}
          {(guacamoleClientState === 5 || guacamoleTunnelState === 2) && (
            <Grid item>
              <Typography color="error" align="center">
                {errorMessage}
              </Typography>
              <Paper elevation={3} style={{ padding: "20px" }}>
                <Grid
                  container
                  item
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      size="small"
                      variant="outlined"
                      color="info"
                      onClick={onDisconnect}
                      id="cancel"
                    >
                      {"Cancel"}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="small"
                      variant="contained"
                      color="info"
                      onClick={onReconnectSSH}
                    >
                      {"ReConnect"}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
          <Grid item xs={12}>
            <div id="SSHdisplay"></div>
            {isEndUser() && (
              <CountdownDialog
                open={countDownOpen}
                countDownTime={accessEndTime.countDownTime}
                onClose={handleCloseCountDownPopup}
                handleDisconnectOkayClick={onDisconnect}
                deviceTimezone={device.timezone}
                onTimeUp={handleTimeUp}
              ></CountdownDialog>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  ) : (
    <Dialog
      open={open && isOpenDialog}
      fullWidth
      PaperProps={{
        style: {
          minHeight: "90vh",
          minWidth: "70vw",
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 1, pb: 0 }} textAlign={"center"}>
        <Typography component="span" sx={{ fontSize: 16 }}>
          {`Connect to device: '${device.name}' IP: ${device.accessProfile?.deviceIP} using ${device.accessProfile?.name} account`}
        </Typography>
        <br />
        {isAdminUser() ? (
          <AdminAccessCountdown deviceTimezone={device.timezone} />
        ) : (
          <CountdownDialog
            open={false}
            countDownTime={accessEndTime.countDownTime}
            handleDisconnectOkayClick={onDisconnect}
            onClose={handleCloseCountDownPopup}
            deviceTimezone={device.timezone}
            onTimeUp={handleTimeUp}
            isShowOnlyCountdown={guacamoleClientState === 3}
          ></CountdownDialog>
        )}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onDisconnect}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent id="container">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          style={{ minHeight: "100%" }}
        >
          {/* Loading State */}
          {(guacamoleTunnelState === 0 ||
            guacamoleClientState === 1 ||
            guacamoleClientState === 2) &&
            guacamoleTunnelState !== 2 && (
              <>
                <Grid item>
                  <CircularProgress />
                </Grid>
                <Grid item>
                  <div>Connecting...</div>
                </Grid>
              </>
            )}
          {/* Error State */}
          {(guacamoleClientState === 5 || guacamoleTunnelState === 2) && (
            <Grid item>
              <Typography color="error" align="center">
                {errorMessage}
              </Typography>
              <Paper elevation={3} style={{ padding: "20px" }}>
                <Grid
                  container
                  item
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      size="small"
                      variant="outlined"
                      color="info"
                      onClick={onDisconnect}
                      id="cancel"
                    >
                      {"Cancel"}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="small"
                      variant="contained"
                      color="info"
                      onClick={onReconnectSSH}
                    >
                      {"ReConnect"}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
          <Grid item xs={12}>
            <div id="SSHdisplay"></div>
            {isEndUser() && (
              <CountdownDialog
                open={countDownOpen}
                countDownTime={accessEndTime.countDownTime}
                handleDisconnectOkayClick={onDisconnect}
                onClose={handleCloseCountDownPopup}
                deviceTimezone={device.timezone}
                onTimeUp={handleTimeUp}
              ></CountdownDialog>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="contained"
          color="info"
          onClick={onDisconnect}
        >
          {"Close"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default RemoteAccessSSH;
