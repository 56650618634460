import { IconButton, Typography } from "@mui/material";
import { ReactElement } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";

type FilterIconProps = {
  handleFilterClick: () => void;
};

const FilterIconButton = (props: FilterIconProps): ReactElement => {
  return (
    <IconButton
      onClick={props.handleFilterClick}
      color="info"
      size="small"
      aria-label="Filter"
    >
      <FilterListIcon />
      <Typography variant="body2" style={{ marginLeft: 4 }}>
        Filter
      </Typography>
    </IconButton>
  );
};

export default FilterIconButton;
