const assets = {
  images: {
    circleLogo: require("./images/circle-logo.png"),
    orangeLogo: require("./images/orange-logo.png"),
    markerIcon: require("./images/marker-icon.png"),
  },
  videos: {
    RDPSCADA: require("./videos/RDPSCADA.mp4"),
    SSHVideo: require("./videos/SSHVideo.mp4"),
  },
};

export default assets;
