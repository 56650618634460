import React, { useCallback, useState, useRef, useEffect } from "react";

import GroupAdd from "@mui/icons-material/GroupAdd";
import {
  CssBaseline,
  Card,
  Drawer,
  Link,
  Button,
  CardHeader,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import apiClient from "common/apiClientAxios";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import { User, UserForm, UserFormMasterData } from "./types";
import AddUser from "./AddUser";
import DeleteUser from "./DeleteUser";
import UsersTable from "./UsersTable";
import EditUser from "./EditUser";
import { useContentStyles } from "common/styles/useContentStyles";
import { UserStatus, Recurrence } from "common/enums";
import { hasPermission } from "common/helpers/utils";
import { DrawerHeader } from "common/styles/styledComponents";
import AddOrganizationDialog from "./AddOrganizationDialog";
import { constants } from "common/constants";

export const userDefaultValues: User = {
  roleName: "",
  userId: "",
  name: "",
  email: "",
  firstName: "",
  lastName: "",
  username: "",
  roleId: "",
  fromTime: "",
  ndnIdentity: "",
  recurrence: "",
  toTime: "",
  groups: [],
  status: UserStatus.ACTIVE,
};
export const userFormDefaultValues: UserForm = {
  email: "",
  firstName: "",
  lastName: "",
  username: "",
  roleId: "",
  fromTime: "",
  ndnIdentity: "",
  recurrence: "",
  toTime: "",
  groups: [],
  accessMethods: [],
  status: UserStatus.ACTIVE,
  password: "",
};
export const recurrences = [
  Recurrence.DAILY,
  Recurrence.WEEKLY,
  Recurrence.MONTHLY,
  Recurrence.YEARLY,
];
export const userStatuses = [UserStatus.ACTIVE, UserStatus.INACTIVE];

const Users: React.FC = () => {
  const classes = useContentStyles();
  const timerRef = useRef<number>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const TIMEOUT = 1000;
  const [openForm, setOpenForm] = useState(false);
  const [isUpdate, setUpdate] = useState(true);
  const [isEdit, setEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User>(userDefaultValues);
  const [openDialogue, setOpenDialogue] = React.useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [userFormMasterData, setUserFormMasterData] =
    useState<UserFormMasterData>({
      organizations: [],
      groups: [],
      countries: [],
      roles: [],
      states: [],
    });
  const isRunEffect = useRef(true);
  const getUserFormMasterData = useCallback(async () => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      const formMasterResponse = await apiClient.get(
        `users/user-form/look-up-data`
      );
      const formMasters = formMasterResponse.data.data as UserFormMasterData;
      setUserFormMasterData(formMasters);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  }, [dispatch]);

  const location = useLocation();

  React.useEffect(() => {
    if (isRunEffect.current) {
      getUserFormMasterData();
      if (location.state?.isAddUserGroup) {
        setShowAddGroupForm(true);
      }
      isRunEffect.current = false;
    }
  }, [getUserFormMasterData, isRunEffect, location.state]);

  const updateTable = useCallback(async () => {
    try {
      const usersResponse = await apiClient.get("/users");
      setUsers(usersResponse.data.data as User[]);
      setUserFormMasterData((prevState) => ({
        ...prevState,
        users: usersResponse.data.data,
      }));
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (isUpdate) {
      updateTable();
      setUpdate(false);
    }
    return () => clearTimeout(timerRef.current);
  }, [isUpdate, updateTable]);

  const openAddForm = () => {
    setOpenForm(true);
  };
  const onCloseForm = () => {
    setOpenForm(false);
  };
  const onCloseEdit = () => {
    setEdit(false);
  };
  const onSaveUpdateTable = () => {
    timerRef.current = window.setTimeout(() => {
      setEdit(false);
      setOpenForm(false);
      setUpdate(true);
    }, TIMEOUT);
  };
  const onDeleteUser = async () => {
    try {
      if (selectedUser?.userId) {
        const usersResponse = await apiClient.delete(
          `/users/${selectedUser.userId}`
        );
        dispatch(
          setSnackbarToast({
            message: usersResponse.data.meta.message,
            open: true,
            severity: "success",
          })
        );
        onDeleteUpdateTable();
      }
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
      setOpenDialogue(false);
    }
  };
  const handleDelete = (user: User) => {
    setSelectedUser(user);
    setOpenDialogue(true);
  };
  const onDeleteUpdateTable = () => {
    setOpenDialogue(false);
    setUpdate(true);
  };
  const onCancelRemove = () => {
    setOpenDialogue(false);
  };
  const onEditUser = (user: User) => {
    setSelectedUser(user);
    setEdit(true);
  };

  const [showAddGroupForm, setShowAddGroupForm] = React.useState(false);
  const openAddGroupForm = () => {
    setShowAddGroupForm(true);
  };
  const hideGroupForm = () => {
    setShowAddGroupForm(false);
    //if user is from groups table then navigate to groups page
    if (location.state?.isAddUserGroup) {
      navigate(`/portal/users/groups`);
    }
  };

  const navigateToGroups = () => {
    //after successful creation of groups navigate to groups page
    setShowAddGroupForm(false);
    navigate(`/portal/users/groups`);
  };

  const [openAddOrgDialogue, setOpenAddOrgDialogue] = React.useState(false);
  const getOrganizations = async () => {
    try {
      const organizationsResponse = await apiClient.get("/organizations");
      setUserFormMasterData((prevState) => ({
        ...prevState,
        organizations: organizationsResponse.data.data,
      }));
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
    setOpenAddOrgDialogue(false);
  };

  const handleFavoriteUser = async (user: User) => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      if (user.isFavorite) {
        await apiClient.delete(`users/favoriteUser/${user.userId}`);
      } else {
        await apiClient.put(`users/favoriteUser/${user.userId}`);
      }
      const usersResponse = await apiClient.get("/users");
      setUsers(usersResponse.data.data as User[]);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.contentPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="none" color="inherit">
            Users
          </Link>
        </Breadcrumbs>
      </div>
      <Card elevation={0} className={classes.contentSection}>
        {!showAddGroupForm ? (
          <CardHeader
            disableTypography
            title="Users"
            className={classes.cardHeader}
            action={
              <>
                <Button
                  onClick={openAddForm}
                  color="info"
                  disabled={!hasPermission("users.summary", "write")}
                  startIcon={<GroupAdd />}
                >
                  {"Add User"}
                </Button>
                <Button
                  onClick={openAddGroupForm}
                  color="info"
                  disabled={!hasPermission("users.groups", "write")}
                  startIcon={<PlaylistAddIcon />}
                >
                  {"Create Group"}
                </Button>
              </>
            }
          ></CardHeader>
        ) : (
          <CardHeader
            disableTypography
            title="Users"
            className={classes.cardHeader}
          ></CardHeader>
        )}
        <Drawer
          anchor="right"
          open={openForm}
          classes={{ paper: classes.formPaper }}
        >
          <DrawerHeader />
          <AddUser
            onSaveUpdateTable={onSaveUpdateTable}
            onClose={onCloseForm}
            userFormMasterData={userFormMasterData}
            openAddOrgForm={() => setOpenAddOrgDialogue(true)}
          ></AddUser>
        </Drawer>
        <Drawer
          anchor="right"
          open={isEdit}
          classes={{ paper: classes.formPaper }}
        >
          <DrawerHeader />
          <EditUser
            onSaveUpdateTable={onSaveUpdateTable}
            onClose={onCloseEdit}
            selectedUser={selectedUser}
            userFormMasterData={userFormMasterData}
            openAddOrgForm={() => setOpenAddOrgDialogue(true)}
          ></EditUser>
        </Drawer>
        {openDialogue && (
          <DeleteUser
            onDeleteUser={onDeleteUser}
            selectedUser={selectedUser}
            open={openDialogue}
            onCancelRemove={onCancelRemove}
          ></DeleteUser>
        )}
        {openAddOrgDialogue && (
          <AddOrganizationDialog
            open={openAddOrgDialogue}
            onCancel={() => setOpenAddOrgDialogue(false)}
            onSave={getOrganizations}
          ></AddOrganizationDialog>
        )}
        <UsersTable
          data={users}
          handleEditUser={onEditUser}
          handleDeleteUser={handleDelete}
          userFormMasterData={userFormMasterData}
          showAddGroupForm={showAddGroupForm}
          hideGroupForm={hideGroupForm}
          navigateToGroups={navigateToGroups}
          handleFavoriteUser={handleFavoriteUser}
        ></UsersTable>
      </Card>
    </React.Fragment>
  );
};

export default Users;
