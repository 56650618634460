import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import React from "react";

import { Device } from "../types";

type DeleteDeviceProps = {
  selectedDevice: Device;
  open: boolean;
  onCancelRemove: () => void;
  onDeleteDevice: () => void;
};

const DeleteDevice: React.FC<DeleteDeviceProps> = (props) => {
  const handleRemove = () => {
    props.onDeleteDevice();
  };

  return (
    <React.Fragment>
      <Dialog open={props.open}>
        <DialogTitle>
          {`Delete device '${props.selectedDevice.name}' ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {"This will remove the device."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.onCancelRemove}
            color="info"
            variant="outlined"
          >
            {"Cancel"}
          </Button>
          <Button
            onClick={handleRemove}
            color="warning"
            autoFocus
            variant="contained"
          >
            {"Remove"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default DeleteDevice;
