import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  CssBaseline,
  Card,
  Link,
  Typography,
  CardHeader,
  Drawer,
  Button,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { useDispatch } from "react-redux";
import { setSnackbarToast } from "redux/UiStateSlice";

import { useContentStyles } from "common/styles/useContentStyles";
import apiClient from "common/apiClientAxios";
import AddOrganization from "./AddOrganization";
import DeleteOrganization from "./DeleteOrganization";
import EditOrganization from "./EditOrganization";
import OrganizationsTable from "./OrganizationsTable";
import { DrawerHeader } from "common/styles/styledComponents";
import { Organization } from "pages/settings/types";
import { hasPermission } from "common/helpers/utils";

const TrustOrganizations: React.FC = () => {
  const classes = useContentStyles();
  const timerRef = useRef<number>();
  const dispatch = useDispatch();
  const TIMEOUT = 1000;
  const [openForm, setOpenForm] = useState(false);
  const [isUpdate, setUpdate] = useState(true);
  const [isEdit, setEdit] = useState(false);
  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization>({
      organizationId: "",
      name: "",
    });
  const [openDialogue, setOpenDialogue] = React.useState(false);

  const [organizations, setOrganizations] = useState<Organization[]>([]);

  const updateTable = useCallback(async () => {
    try {
      const organizationsResponse = await apiClient.get("/organizations");
      setOrganizations(organizationsResponse.data.data as Organization[]);
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (isUpdate) {
      updateTable();
      setUpdate(false);
    }
    return () => clearTimeout(timerRef.current);
  }, [isUpdate, updateTable]);

  const openAddForm = () => {
    setOpenForm(true);
  };
  const onCloseForm = () => {
    setOpenForm(false);
  };
  const onCloseEdit = () => {
    setEdit(false);
  };

  const onSaveUpdateTable = () => {
    timerRef.current = window.setTimeout(() => {
      setEdit(false);
      setOpenForm(false);
      setUpdate(true);
    }, TIMEOUT);
  };

  const onDeleteOrganization = async () => {
    try {
      if (selectedOrganization.organizationId) {
        const deleteResponse = await apiClient.delete(
          `/organizations/${selectedOrganization.organizationId}`
        );
        dispatch(
          setSnackbarToast({
            message: deleteResponse.data.meta.message,
            open: true,
            severity: "success",
          })
        );
        onDeleteUpdateTable();
      }
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
      setOpenDialogue(false);
    }
  };

  const handleDeleteOrganization = (obj: Organization) => {
    setSelectedOrganization(obj);
    setOpenDialogue(true);
  };

  const onDeleteUpdateTable = () => {
    setOpenDialogue(false);
    setUpdate(true);
  };

  const onCancelRemove = () => {
    setOpenDialogue(false);
  };

  const onEditOrganization = (obj: Organization) => {
    setSelectedOrganization(obj);
    setEdit(true);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.contentPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="none" color="inherit">
            Resources
          </Link>
          <Typography variant="body1">{"Companies/Organizations"}</Typography>
        </Breadcrumbs>
      </div>
      <Card elevation={0} className={classes.contentSection}>
        <CardHeader
          disableTypography
          title="Organizations"
          className={classes.cardHeader}
          action={
            <Button
              onClick={openAddForm}
              color="info"
              disabled={!hasPermission("organizations", "write")}
              startIcon={<PlaylistAddIcon />}
            >
              {"Add Organization"}
            </Button>
          }
        ></CardHeader>
        <Drawer
          anchor="right"
          open={openForm}
          classes={{ paper: classes.formPaper }}
        >
          <DrawerHeader />
          <AddOrganization
            onSaveUpdateTable={onSaveUpdateTable}
            onClose={onCloseForm}
          ></AddOrganization>
        </Drawer>
        <Drawer
          anchor="right"
          open={isEdit}
          classes={{ paper: classes.formPaper }}
        >
          <DrawerHeader />
          <EditOrganization
            onSaveUpdateTable={onSaveUpdateTable}
            onClose={onCloseEdit}
            selectedOrganization={selectedOrganization}
          ></EditOrganization>
        </Drawer>
        {openDialogue && (
          <DeleteOrganization
            onDeleteOrganization={onDeleteOrganization}
            selectedOrganization={selectedOrganization}
            open={openDialogue}
            onCancelRemove={onCancelRemove}
          ></DeleteOrganization>
        )}
        <OrganizationsTable
          data={organizations}
          handleEditOrganization={onEditOrganization}
          handleDeleteOrganization={handleDeleteOrganization}
        ></OrganizationsTable>
      </Card>
    </React.Fragment>
  );
};

export default TrustOrganizations;
