import * as React from "react";
import { useEffect } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { constants } from "common/constants";
import { Organization } from "pages/settings/types";
import { hasPermission } from "common/helpers/utils";
import NoRowsOverlay from "common/components/NoRowsOverlay";
import { CustomToolbarWithOnlySearch } from "common/components/CustomToolbarWithOnlySearch";

type OrganizationsTableProps = {
  data: Organization[];
  handleEditOrganization: (org: Organization) => void;
  handleDeleteOrganization: (org: Organization) => void;
};

const OrganizationsTable: React.FC<OrganizationsTableProps> = (props) => {
  const [rows, setRows] = React.useState<Organization[]>(props.data);

  useEffect(() => {
    setRows(props.data);
  }, [props.data]);

  const editOrganization = React.useCallback(
    (gridRow: any) => () => {
      props.handleEditOrganization(gridRow.row);
    },
    [props]
  );

  const deleteOrganization = React.useCallback(
    (gridRow: any) => () => {
      props.handleDeleteOrganization(gridRow.row);
    },
    [props]
  );

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "name",
        type: "string",
        headerName: "Company/Organization Name",
        flex: 1,
      },
      {
        field: "description",
        type: "string",
        headerName: "Description",
        flex: 1,
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        flex: 1,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon color="info" />}
            label="Edit"
            disabled={!hasPermission("organizations", "write")}
            onClick={editOrganization(params)}
            showInMenu
            key="editOrganization"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon color="info" />}
            label="Delete"
            disabled={!hasPermission("organizations", "delete")}
            onClick={deleteOrganization(params)}
            showInMenu
            key="deleteOrganization"
          />,
        ],
      },
    ],
    [deleteOrganization, editOrganization]
  );

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      getRowId={(row) => row.organizationId}
      pageSizeOptions={constants.PAGE_SIZE_OPTIONS}
      initialState={{
        pagination: { paginationModel: constants.PAGINATION_MODEL },
      }}
      autoHeight
      slots={{
        toolbar: CustomToolbarWithOnlySearch,
        noRowsOverlay: () => (
          <NoRowsOverlay
            hasAccess={hasPermission("organizations", "read")}
            name="Organizations"
          />
        ),
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      sx={{
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: "600",
        },
      }}
    />
  );
};

export default OrganizationsTable;
