import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

import { UserGroup } from "../types";

type DeleteGroupProps = {
  selectedGroup: UserGroup;
  open: boolean;
  onCancelRemove: () => void;
  onDeleteGroup: () => void;
};

const DeleteUserGroup: React.FC<DeleteGroupProps> = (props) => {
  const handleRemove = () => {
    props.onDeleteGroup();
  };

  return (
    <React.Fragment>
      <Dialog open={props.open}>
        <DialogTitle>
          {`Delete user group '${props.selectedGroup.name}' ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {"This will remove the user group."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.onCancelRemove}
            color="info"
            variant="outlined"
          >
            {"Cancel"}
          </Button>
          <Button
            onClick={handleRemove}
            color="warning"
            autoFocus
            variant="contained"
          >
            {"Remove"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default DeleteUserGroup;
