import { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useSelector } from "react-redux";

import SidebarItem from "./SidebarItem";
import { RootState } from "../../redux/Store";
import { RouteType } from "../../routes/types";
import { ArrowRight } from "@mui/icons-material";

type Props = {
  item: RouteType;
};

const SidebarItemCollapse = ({ item }: Props) => {
  const [open, setOpen] = useState(false);
  const { appState } = useSelector((state: RootState) => state.appState);

  useEffect(() => {
    if (appState.includes(item.state)) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [appState, item]);

  return item.sidebarProps ? (
    <>
      <ListItemButton
        onClick={() => setOpen(!open)}
        sx={{
          "&: hover": {
            backgroundColor: "secondary.light",
          },
          paddingY: 1, //'8px',
          paddingX: 3, //'24px',
        }}
      >
        <ListItemIcon
          sx={{
            color: "unset",
          }}
        >
          {item.sidebarProps.icon ?? null}
        </ListItemIcon>
        <ListItemText primary={item.sidebarProps.displayText} />
        {open ? (
          <ArrowDropDownIcon color="secondary" fontSize="large" />
        ) : (
          <ArrowRight color="secondary" fontSize="large" />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <List>
          {item.child?.map((route, index) => {
            if (!route.sidebarProps) {
              return null;
            }
            if (route.child) {
              return (
                <SidebarItemCollapse
                  item={route}
                  key={`route_${route.state}_${index}`}
                />
              );
            }
            return (
              <SidebarItem item={route} key={`route_${route.state}_${index}`} />
            );
          })}
        </List>
      </Collapse>
    </>
  ) : null;
};

export default SidebarItemCollapse;
