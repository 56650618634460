import React, { useCallback, useRef, useState } from "react";

import {
  CssBaseline,
  Card,
  Drawer,
  Link,
  Typography,
  Button,
  CardHeader,
  Box,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import apiClient from "common/apiClientAxios";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import { useDispatch } from "react-redux";
import { useContentStyles } from "common/styles/useContentStyles";
import { DrawerHeader } from "common/styles/styledComponents";
import { Site } from "pages/sites/types";
import { ConnectionSetting, ConnectionSettingFormMaster } from "../types";
import { constants } from "common/constants";
import HttpsConnectionForm from "./HttpsConnectionForm";
import HttpsConnectionsTable from "./HttpsConnectionsTable";
import DeleteHttpsConnection from "./DeleteHttpsConnection";

const HttpsConnections: React.FC = () => {
  const classes = useContentStyles();
  const dispatch = useDispatch();
  const isRunEffect = useRef(true);
  const [openForm, setOpenForm] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [openDialogue, setOpenDialogue] = React.useState(false);
  const [settings, setSettings] = React.useState<ConnectionSetting[]>([]);
  const [selectedSetting, setSelectedSetting] =
    React.useState<ConnectionSetting>({
      ip: "",
      password: "",
      port: "",
      username: "",
      connectionLimit: "",
    });
  const [sites, setSites] = useState<Site[]>([]);
  const [defaultConnectionLimit, setDefaultConnectionLimit] = useState("");

  const getConnectionSettingsFormMaster = useCallback(async () => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      const settingsResponse = await apiClient.get(
        `settings/https-xrdp/formData`
      );
      const settingsData = settingsResponse.data
        .data as ConnectionSettingFormMaster;
      settingsData.settings?.forEach((record) => {
        record.siteName =
          settingsData.sites?.find((site) => site.siteId === record.siteId)
            ?.name ?? "-";
      });
      setSettings(settingsData.settings);
      setSites(settingsData.sites);
      setDefaultConnectionLimit(settingsData.defaultConnectionLimit);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  }, [dispatch]);

  const handleSave = async (data: ConnectionSetting) => {
    try {
      if (!data.id) {
        const existingConnectionsForSite = settings.filter(
          (setting) => setting.siteId === data.siteId
        );
        const existingConnectionsCount = existingConnectionsForSite.length;
        if (existingConnectionsCount >= +data.connectionLimit) {
          dispatch(
            setSnackbarToast({
              message: `Maximum connections limit reached.`,
              open: true,
              severity: "error",
            })
          );
          return;
        }
      }
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      await apiClient.post(`settings/https-xrdp`, {
        ...data,
      });
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      setOpenForm(false);
      getConnectionSettingsFormMaster();
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      setOpenForm(false);
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  };

  React.useEffect(() => {
    if (isRunEffect.current) {
      getConnectionSettingsFormMaster();
      isRunEffect.current = false;
    }
  }, [getConnectionSettingsFormMaster]);

  const openAddForm = () => {
    setSelectedSetting({
      ip: "",
      password: "",
      port: "",
      username: "",
      connectionLimit: "",
    });
    setEdit(false);
    setOpenForm(true);
  };

  const onCloseForm = () => {
    setOpenForm(false);
    setEdit(false);
  };

  const openEditForm = (setting: ConnectionSetting) => {
    setSelectedSetting(setting);
    setEdit(true);
    setOpenForm(true);
  };

  const onDeleteSettings = async () => {
    try {
      const deleteResponse = await apiClient.delete(
        `/settings/https-xrdp/${selectedSetting.siteId}`
      );
      dispatch(
        setSnackbarToast({
          message: deleteResponse.data.meta.message,
          open: true,
          severity: "success",
        })
      );
      onDeleteUpdateTable();
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
      setOpenDialogue(false);
    }
  };

  const openDeleteDialog = (setting: ConnectionSetting) => {
    setSelectedSetting(setting);
    setOpenDialogue(true);
  };

  const onDeleteUpdateTable = () => {
    setOpenDialogue(false);
    getConnectionSettingsFormMaster();
  };

  const onCancelRemove = () => {
    setOpenDialogue(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.contentPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="none" color="inherit">
            Settings
          </Link>
          <Typography variant="body1">{"Http/s Connection"}</Typography>
        </Breadcrumbs>
      </div>
      <Card elevation={0} className={classes.contentSection}>
        <CardHeader
          disableTypography
          title="Http/s Connection Settings"
          className={classes.cardHeader}
          action={
            <Box display="flex" alignItems="center">
              <Button
                onClick={openAddForm}
                color="info"
                disabled
                startIcon={<PlaylistAddIcon />}
              >
                {"Add Connection Setting"}
              </Button>
            </Box>
          }
        ></CardHeader>
        <Drawer
          anchor="right"
          open={openForm}
          classes={{ paper: classes.formPaper }}
        >
          <DrawerHeader />
          <HttpsConnectionForm
            connectionSetting={selectedSetting}
            handleCancel={onCloseForm}
            handleSave={handleSave}
            isEdit={isEdit}
            connectionLimit={defaultConnectionLimit}
            settings={settings}
            sites={sites}
          ></HttpsConnectionForm>
        </Drawer>
        {openDialogue && (
          <DeleteHttpsConnection
            onDelete={onDeleteSettings}
            selectedSetting={selectedSetting}
            open={openDialogue}
            onCancelRemove={onCancelRemove}
          ></DeleteHttpsConnection>
        )}
        <HttpsConnectionsTable
          data={settings}
          connectionLimit={defaultConnectionLimit}
          handleDelete={openDeleteDialog}
          handleEdit={openEditForm}
        ></HttpsConnectionsTable>
      </Card>
    </React.Fragment>
  );
};

export default HttpsConnections;
