import React from "react";
import { Chip, Grid, Button } from "@mui/material";
import { FormMasterData, RuleFilter } from "../types";
import { accessProfiles, ruleStatuses } from "./RulesFilter";

interface RulesFilterChipDisplayProps {
  filters: RuleFilter;
  rulesFormMasterData: FormMasterData;
  handleUpdatedFilter: (filters: RuleFilter) => void;
  openFilter: () => void;
}

const RulesFilterChipDisplay: React.FC<RulesFilterChipDisplayProps> = ({
  filters,
  handleUpdatedFilter,
  rulesFormMasterData,
  openFilter,
}) => {
  const handleFilterOptionDelete = (
    option: string,
    filtersData: string[],
    type: keyof RuleFilter
  ) => {
    const updated = filtersData.filter((method) => method !== option);
    handleUpdatedFilter({ ...filters, [type]: updated });
  };

  const renderChips = (
    data: string[],
    type: keyof RuleFilter,
    findOption: (
      value: string
    ) => { id?: string; name?: string; label?: string } | undefined,
    labelKey?: keyof { name?: string; label?: string }
  ) => {
    return data.map((value) => {
      const option = findOption(value);
      return (
        <Chip
          variant="outlined"
          key={value}
          label={option?.[labelKey ?? "name"]}
          onDelete={() => handleFilterOptionDelete(value, data, type)}
        />
      );
    });
  };

  const chips = [
    ...renderChips(filters.devicesGroups, "devicesGroups", (value) =>
      rulesFormMasterData.deviceGroups.find((opt) => opt.groupId === value)
    ),
    ...renderChips(filters.userGroups, "userGroups", (value) =>
      rulesFormMasterData.userGroups.find((opt) => opt.groupId === value)
    ),
    ...renderChips(
      filters.accessProfiles,
      "accessProfiles",
      (value) => accessProfiles.find((opt) => opt.label === value),
      "label"
    ),
    ...renderChips(
      filters.statuses,
      "statuses",
      (value) => ruleStatuses.find((opt) => opt.label === value),
      "label"
    ),
  ];

  return (
    <Grid container spacing={1} paddingBottom={1} paddingLeft={"2px"}>
      {chips.slice(0, 7).map((chip, index) => (
        <Grid item key={index}>
          {chip}
        </Grid>
      ))}
      {chips.length > 7 && (
        <>
          <Grid item>...</Grid>
          <Button onClick={openFilter} color="info">
            Show All
          </Button>
        </>
      )}
    </Grid>
  );
};

export default RulesFilterChipDisplay;
