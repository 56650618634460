import React, { ReactNode, useCallback, useEffect } from "react";

import apiClient from "common/apiClientAxios";
import { FeatureSetting } from "pages/settings/types";
import { RouteType } from "./types";
import { FeatureStatus } from "common/enums";
import { generateRoute, getAppRoutes } from "./AppRoutes";
import { useDispatch } from "react-redux";
import { setSnackbarToast } from "redux/UiStateSlice";
import { getAccessDeniedMenus } from "../common/helpers/utils";

function useAppRoutes() {
  const [filteredAppRoutes, setFilteredAppRoutes] = React.useState<RouteType[]>(
    []
  );
  const [generatedAppRoutes, setGeneratedAppRoutes] =
    React.useState<ReactNode>();
  const dispatch = useDispatch();

  const filterRoutesArray = useCallback(
    (routes: RouteType[], statusMap: any) => {
      const accessDeniedMenus = getAccessDeniedMenus();
      return routes
        .map((route) => {
          if (
            route.state &&
            statusMap[route.state] === FeatureStatus.DISABLED
          ) {
            return null; // Remove the item if status is 'Disabled'
          }

          //based on role access menus settings render UI
          const sessionUser = sessionStorage.getItem("user")
            ? sessionStorage.getItem("user")
            : localStorage.getItem("user");
          if (
            !!sessionUser &&
            route.state &&
            accessDeniedMenus.includes(route.state)
          ) {
            return null;
          }

          if (route.child) {
            route.child = filterRoutesArray(
              route.child,
              statusMap
            ) as RouteType[];
          }
          return route;
        })
        .filter(Boolean);
    },
    []
  );

  const getFeatureSettings = useCallback(async () => {
    try {
      const featuresResponse = await apiClient.get("/settings/features");
      if (featuresResponse && featuresResponse?.data) {
        const featureList = featuresResponse?.data?.data as FeatureSetting[];
        const statusMap = featureList.reduce((map: any, item) => {
          map[item.featureId] = item.status;
          return map;
        }, {});
        const appRoutes = getAppRoutes();
        const routes = filterRoutesArray(appRoutes, statusMap) as RouteType[];
        if (routes) setFilteredAppRoutes(routes);
        const generatedRoutes = generateRoute(routes);
        setGeneratedAppRoutes(generatedRoutes);
      }
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  }, [dispatch, filterRoutesArray]);

  useEffect(() => {
    getFeatureSettings();
  }, [getFeatureSettings]);
  return {
    filteredAppRoutes,
    generatedAppRoutes,
  };
}
export default useAppRoutes;
