import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Paper,
} from "@mui/material";

import { User } from "./types";
import { useContentStyles } from "common/styles/useContentStyles";

type StaticToDynamicDialogProps = {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  newUsers: User[];
};

const StaticToDynamicDialog: React.FC<StaticToDynamicDialogProps> = ({
  open,
  onCancel,
  onConfirm,
  newUsers,
}) => {
  const classes = useContentStyles();
  return (
    <React.Fragment>
      <Dialog open={open}>
        <DialogContent>
          You are about to change from Static to Dynamic. All items now and in
          the future with the selected attributes will be part of the group.
          {newUsers.length > 0 ? (
            <>
              Currently this adds the following items that were not part of the
              group before:
              <Paper className={classes.dialogListContainer}>
                <ul className={classes.ulListItem}>
                  {newUsers.map((user) => (
                    <li key={user.userId}>
                      {`${user.firstName} ${user.lastName}`}
                    </li>
                  ))}
                </ul>
              </Paper>
            </>
          ) : (
            <>So far, this makes no changes to the group membership</>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="info" variant="outlined">
            {"No"}
          </Button>
          <Button
            onClick={onConfirm}
            color="info"
            autoFocus
            variant="contained"
          >
            {"Yes"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default StaticToDynamicDialog;
