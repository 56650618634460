import React, { useState } from "react";
import {
  Button,
  IconButton,
  Container,
  Typography,
  TextField,
  Grid,
  Tooltip,
  Link,
} from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useDispatch } from "react-redux";

import apiClient from "common/apiClientAxios";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import { constants } from "common/constants";

const GenerateApiKey: React.FC = () => {
  const dispatch = useDispatch();
  const [generatedKey, setGeneratedKey] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleGenerateKey = async () => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      const apiKeyResponse = await apiClient.get(`/auth/api-key`);
      setGeneratedKey(apiKeyResponse.data.data);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(generatedKey);
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 1000);
  };

  const handleDownloadFile = async () => {
    try {
      const response = await fetch(
        `${window.location.origin}/open-api/api-key-apis.yaml`
      );
      const data = await response.text();
      const blob = new Blob([data], { type: "application/x-yaml" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "swagger-api.yaml";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      dispatch(
        setSnackbarToast({
          message: `Error fetching YAML data: ${error}`,
          open: true,
          severity: "error",
        })
      );
    }
  };

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Typography variant="h5" gutterBottom>
            Generate Access Key
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Grid container>
            <Grid item xs={12}>
              <Link
                sx={{
                  color: (theme) => theme.palette.info.main,
                  textDecorationColor: (theme) => theme.palette.info.main,
                  float: "right",
                }}
                href="/open-api/index.html"
                target="_blank"
              >
                View API Documentation
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Link
                component="button"
                sx={{
                  color: (theme) => theme.palette.info.main,
                  textDecorationColor: (theme) => theme.palette.info.main,
                  float: "right",
                }}
                onClick={async () => await handleDownloadFile()}
              >
                Download api file
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            variant="contained"
            color="info"
            onClick={handleGenerateKey}
            fullWidth
            size="large"
            sx={{ marginBottom: 2 }}
          >
            Generate Key
          </Button>
        </Grid>
        <Grid item xs={10}>
          <TextField
            value={generatedKey}
            disabled
            fullWidth
            placeholder="Generated Key will display here"
            name="generatedKey"
            multiline
            rows={3}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip
            title="Copied to clipboard"
            arrow
            open={tooltipOpen}
            disableHoverListener
          >
            <IconButton onClick={handleCopyToClipboard}>
              <FileCopyIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Container>
  );
};

export default GenerateApiKey;
