import React, { ReactElement, useRef, useState } from "react";
import { Portal, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useSelector } from "react-redux";

import { RootState } from "redux/Store";
import { setSnackbarToast } from "redux/UiStateSlice";

const SnackbarToast = (): ReactElement => {
  const [open, setOpen] = useState(false);
  const timerRef = useRef<number>();

  const { snackBarToast } = useSelector((state: RootState) => state.uiState);

  React.useEffect(() => {
    setOpen(snackBarToast.open);
    timerRef.current = window.setTimeout(() => {
      if (snackBarToast.open) {
        setSnackbarToast({
          message: "",
          open: false,
          severity: "info",
        });
      }
    }, 2000);
    return () => clearTimeout(timerRef.current);
  }, [snackBarToast]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Portal>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={snackBarToast.severity}
        >
          {snackBarToast.message}
        </MuiAlert>
      </Snackbar>
    </Portal>
  );
};
export default SnackbarToast;
