import React, { useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import TrustOrganizations from "./Organizations/Organizations";
import Roles from "./Roles/Roles";
import Manufacturers from "./Manufacturers/Manufacturers";
import SiteOperators from "./SiteOperators/SiteOperators";
import SiteOwners from "./SiteOwners/SiteOwners";

const ResourcesPage: React.FC = () => {
  const [tabValue, setTabValue] = useState("1");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <TabContext value={tabValue}>
      <TabList
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="secondary"
      >
        <Tab label="Companies/Organizations" value={"1"} />
        <Tab label="Roles" value={"2"} />
        <Tab label="Manufacturers" value={"3"} />
        <Tab label="Site Operators" value={"4"} />
        <Tab label="Site Owners" value={"5"} />
      </TabList>
      <TabPanel value="1">
        <TrustOrganizations />
      </TabPanel>
      <TabPanel value="2">
        <Roles />
      </TabPanel>
      <TabPanel value="3">
        <Manufacturers />
      </TabPanel>
      <TabPanel value="4">
        <SiteOperators />
      </TabPanel>
      <TabPanel value="5">
        <SiteOwners />
      </TabPanel>
    </TabContext>
  );
};

export default ResourcesPage;
