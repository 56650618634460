import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  CssBaseline,
  Card,
  Link,
  CardHeader,
  Button,
  Box,
  Typography,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { RootState } from "redux/Store";
import { useDispatch, useSelector } from "react-redux";
import { useContentStyles } from "common/styles/useContentStyles";
import { defaultValues } from "./AddRule";
import apiClient from "common/apiClientAxios";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import { FormMasterData, Rule } from "../types";
import RulesTable from "./RulesTable";
import DeleteRule from "./DeleteRule";
import { useNavigate } from "react-router-dom";
import {
  getAccessWindowDisplaySetting,
  getDateRangeAccessWindow,
  getRepeatingAccessWindow,
  getTimezone,
  hasPermission,
} from "common/helpers/utils";
import { constants } from "common/constants";
import { AccessWindowType, RuleTemplate } from "common/enums";

const Rules: React.FC = () => {
  const classes = useContentStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData } = useSelector((state: RootState) => state.userState);
  const [isUpdate, setUpdate] = useState(true);
  const [selectedRule, setSelectedRule] = useState<Rule>({
    ...defaultValues,
    ruleId: "",
    timezone: "",
  });
  const [openDialogue, setOpenDialogue] = React.useState(false);
  const [rules, setRules] = useState<Rule[]>([]);
  const accessWindowDisplay = getAccessWindowDisplaySetting(
    userData.accessWindowDisplay
  );
  const updateTable = useCallback(async () => {
    const timezone = getTimezone();
    try {
      const rulesResponse = await apiClient.get(
        `/trust-rules?timezone=${timezone}`
      );
      setRules(rulesResponse.data.data as Rule[]);
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (isUpdate) {
      updateTable();
      setUpdate(false);
    }
  }, [isUpdate, updateTable]);

  //on click of add zone
  const openAddForm = () => {
    navigate(`/portal/trust/list/new`, {
      state: { formMasterData },
    });
  };

  const onDeleteRule = async () => {
    try {
      if (selectedRule.ruleId) {
        const zoneDeleteResponse = await apiClient.delete(
          `/trust-rules/${selectedRule.ruleId}`
        );
        dispatch(
          setSnackbarToast({
            message: zoneDeleteResponse.data.meta.message,
            open: true,
            severity: "success",
          })
        );
        onDeleteUpdateTable();
      }
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
      setOpenDialogue(false);
    }
  };

  const handleDeleteRule = (ruleObj: Rule) => {
    setSelectedRule(ruleObj);
    setOpenDialogue(true);
  };

  const onDeleteUpdateTable = () => {
    setOpenDialogue(false);
    setUpdate(true);
  };

  const onCancelRemove = () => {
    setOpenDialogue(false);
  };

  const handleEditRule = (ruleObj: Rule) => {
    navigate(`/portal/trust/list/edit`, {
      state: { formMasterData, selectedRule: ruleObj },
    });
  };

  const [formMasterData, setFormMasterData] = useState<FormMasterData>({
    userGroups: [],
    deviceGroups: [],
    rules: [],
  });
  const isRunEffect = useRef(true);

  const getFormMasterData = useCallback(async () => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      const formMasterResponse = await apiClient.get(
        `trust-rules/form/look-up-data`
      );
      const formMasters = formMasterResponse.data.data as FormMasterData;
      setFormMasterData(formMasters);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  }, [dispatch]);

  React.useEffect(() => {
    if (isRunEffect.current) {
      getFormMasterData();
      isRunEffect.current = false;
    }
  }, [getFormMasterData, isRunEffect]);

  const handleFavoriteRule = async (rule: Rule) => {
    const timezone = getTimezone();
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      if (rule.isFavorite) {
        await apiClient.delete(`users/favoriteRule/${rule.ruleId}`);
      } else {
        await apiClient.put(`users/favoriteRule/${rule.ruleId}`);
      }
      const rulesResponse = await apiClient.get(
        `/trust-rules?timezone=${timezone}`
      );
      setRules(rulesResponse.data.data as Rule[]);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  };

  const changeRuleStatus = async (rule: Rule) => {
    const timezone = getTimezone();
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      await apiClient.put(`trust-rules/${rule.ruleId}`, {
        ...rule,
        timezone: getTimezone(),
      });
      const rulesResponse = await apiClient.get(
        `/trust-rules?timezone=${timezone}`
      );
      setRules(rulesResponse.data.data as Rule[]);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  };

  useEffect(() => {
    const intervalId = setInterval(updateTable, 5 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [updateTable]);

  useEffect(() => {
    rules.forEach((row) => {
      row.accessWindowType === AccessWindowType.DATE_RANGE
        ? (row.accessWindow = getDateRangeAccessWindow({
            startDate: row.startDate,
            endDate: row.endDate,
            fromTime: row.startTime,
            toTime: row.endTime,
            daysTemplate: row.daysTemplate as RuleTemplate,
            schedule: row.schedule,
            userZone: accessWindowDisplay.zone,
            timeSelectionType: row.timeSelectionType as RuleTemplate,
          }))
        : (row.accessWindow = getRepeatingAccessWindow({
            startDate: row.recurrenceStartDate,
            endDate: row.recurrenceEndDate,
            fromTime: row.recurrenceStartTime,
            toTime: row.recurrenceEndTime,
            schedule: row.recurrenceWeekSchedule,
            recurrence: row.recurrence ?? "1",
            recurrenceUnit: row.recurrenceUnit,
            recurrenceMonth: row.recurrenceMonth,
            userZone: accessWindowDisplay.zone,
          }));
    });
  }, [accessWindowDisplay.zone, rules]);

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.contentPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="none" color="inherit">
            Trust Rules
          </Link>
        </Breadcrumbs>
      </div>
      <Card elevation={0} className={classes.contentSection}>
        <CardHeader
          disableTypography
          title="Trust List"
          className={classes.cardHeader}
          action={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: (theme) => theme.palette.info.main,
              }}
            >
              <Box
                sx={{
                  paddingRight: 5,
                  marginRight: 5,
                }}
              >
                <Typography>
                  Access window displayed in:{" "}
                  <strong>{accessWindowDisplay.display}</strong>
                  {/* <TcTooltip
                    title={constants.ACCESS_WINDOW_DISPLAY_INFO_MESSAGE}>
                    <IconButton size="small" color="info">
                      <InfoOutlinedIcon fontSize="inherit" />
                    </IconButton>
                  </TcTooltip> */}
                </Typography>
              </Box>
              <Button
                onClick={openAddForm}
                color="info"
                disabled={!hasPermission("trust.list", "write")}
                startIcon={<PlaylistAddIcon />}
              >
                {"Add Rule"}
              </Button>
            </Box>
          }
        ></CardHeader>
        {openDialogue && (
          <DeleteRule
            onDeleteRule={onDeleteRule}
            selectedRule={selectedRule}
            open={openDialogue}
            onCancelRemove={onCancelRemove}
          />
        )}
        <RulesTable
          data={rules}
          handleDeleteRule={handleDeleteRule}
          handleEditRule={handleEditRule}
          handleFavoriteRule={handleFavoriteRule}
          formMasterData={formMasterData}
          changeRuleStatus={changeRuleStatus}
        />
      </Card>
    </React.Fragment>
  );
};

export default Rules;
