import React, { ReactElement } from "react";
import { CircularProgress, Backdrop, Typography, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Portal from "@mui/material/Portal";
import { useSelector } from "react-redux";
import { RootState } from "redux/Store";

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: theme.palette.background.default,
  },
}));

const BackDropLoader = (): ReactElement => {
  const classes = useStyles();
  const { loader } = useSelector((state: RootState) => state.uiState);

  return (
    <Portal>
      <Backdrop className={classes.backdrop} open={loader.openLoader}>
        <CircularProgress color="inherit" />
        <Typography component="h5" variant="h5">
          {loader.loaderMessage}
        </Typography>
      </Backdrop>
    </Portal>
  );
};
export default BackDropLoader;
