import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { clearUserData } from "redux/UserStateSlice";
import { constants } from "common/constants";
import { IdentityAccessProvider } from "common/enums";
import { setSnackbarToast } from "redux/UiStateSlice";

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { instance, inProgress } = useMsal();
  async function loginWithOkta() {
    // Use the Okta SDK to initiate the login process
    // This may involve redirecting the user to the Okta login page
    // and handling the callback to obtain tokens and user information
    // Once authenticated, set the user state
    // You can extract user information from the tokens received
    // and save them in your user state
  }

  function generateUniqueState() {
    // Define the length and characters for the state string
    const length = 32; // You can adjust the length as needed
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    let state = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      state += characters.charAt(randomIndex);
    }
    return state;
  }

  const detectBrowserName = () => {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf("edge") > -1 || agent.indexOf("edg") > -1:
        return "edge";
      case agent.indexOf("opr") > -1 && !!(<any>window).opr:
        return "opera";
      case agent.indexOf("chrome") > -1 && !!(<any>window).chrome:
        return "chrome";
      case agent.indexOf("trident") > -1:
        return "ie";
      case agent.indexOf("firefox") > -1:
        return "firefox";
      case agent.indexOf("safari") > -1:
        return "safari";
      default:
        return "other";
    }
  };

  // Define a function to log in with Azure AD
  async function loginWithAzureAD() {
    if (instance && inProgress === InteractionStatus.None) {
      localStorage.setItem("login_with", IdentityAccessProvider.AZURE);
      try {
        const browser = detectBrowserName();
        //For other than 'Edge' redirect login
        if (browser !== "edge") {
          await instance.loginRedirect({
            scopes: ["profile"],
            redirectUri: instance.getConfiguration().auth.redirectUri,
            redirectStartPage: instance.getConfiguration().auth.redirectUri,
            state: generateUniqueState(),
          });
        } else {
          await instance.loginPopup({
            scopes: ["profile"],
            redirectUri: instance.getConfiguration().auth.redirectUri,
            state: generateUniqueState(),
          });
          navigate("/redirect");
        }
      } catch (error) {
        dispatch(
          setSnackbarToast({
            message: "Error in login with Azure. Please try again",
            open: true,
            severity: "error",
          })
        );
      }
    }
  }

  function clearLocalStorage() {
    if (sessionStorage.getItem("access_token")) {
      sessionStorage.clear();
    } else {
      sessionStorage.clear();
      localStorage.clear();
    }
    dispatch(clearUserData());
  }

  async function logout() {
    const loginWith = sessionStorage.getItem("login_with")
      ? sessionStorage.getItem("login_with")
      : localStorage.getItem("login_with");
    if (instance && loginWith === IdentityAccessProvider.AZURE) {
      clearLocalStorage();
      await instance.logoutRedirect({
        postLogoutRedirectUri:
          instance.getConfiguration().auth.postLogoutRedirectUri,
      });
    } else {
      clearLocalStorage();
      navigate(constants.LOGIN_PATH);
    }
  }

  return {
    loginWithOkta,
    loginWithAzureAD,
    logout,
  };
}

export default useAuth;
