import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import AddOrganization from "pages/settings/Resources/Organizations/AddOrganization";

type AddOrganizationDialogProps = {
  open: boolean;
  onCancel: () => void;
  onSave: () => void;
};

const AddOrganizationDialog: React.FC<AddOrganizationDialogProps> = ({
  open,
  onCancel,
  onSave,
}) => {
  return (
    <React.Fragment>
      <Dialog open={open}>
        <DialogContent>
          <AddOrganization
            onClose={onCancel}
            onSaveUpdateTable={onSave}
          ></AddOrganization>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
export default AddOrganizationDialog;
