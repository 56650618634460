import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import AddManufacturer from "pages/settings/Resources/Manufacturers/AddManufacturer";

type AddManufacturerDialogProps = {
  open: boolean;
  onCancel: () => void;
  onSave: () => void;
};

const AddManufacturerDialog: React.FC<AddManufacturerDialogProps> = ({
  open,
  onCancel,
  onSave,
}) => {
  return (
    <React.Fragment>
      <Dialog open={open}>
        <DialogContent>
          <AddManufacturer
            onClose={onCancel}
            onSaveUpdateTable={onSave}
          ></AddManufacturer>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
export default AddManufacturerDialog;
