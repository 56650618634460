import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Grid, Button } from "@mui/material";
import { constants } from "common/constants";
import { Site } from "pages/sites/types";
import TcSelectDropdown from "common/components/TcSelectDropdown";
import TcTextField from "common/components/TcTextField";
import { ConnectionSetting, ConnectionSettingForm } from "../types";
import TcPasswordTextField from "common/components/TcPasswordTextField";
import FormHeader from "common/components/FormHeader";
import { useDrawerFormStyles } from "common/styles/useDrawerFormStyles";

type HttpsConnectionFormProps = {
  connectionSetting: ConnectionSetting;
  sites: Site[];
  isEdit: boolean;
  handleSave: (data: ConnectionSetting) => void;
  handleCancel: () => void;
  connectionLimit: string;
  settings: ConnectionSetting[];
};
const HttpsConnectionForm: React.FC<HttpsConnectionFormProps> = ({
  connectionSetting,
  sites,
  handleCancel,
  handleSave,
  connectionLimit,
  settings,
}) => {
  const classes = useDrawerFormStyles();
  const methods = useForm<ConnectionSettingForm>({
    mode: "all",
    defaultValues: {
      ...connectionSetting,
      connectionLimit: connectionSetting.connectionLimit
        ? connectionSetting.connectionLimit
        : connectionLimit,
    },
  });

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isValid, isDirty },
  } = methods;
  const selectedSiteId = watch(`siteId`);
  const handleSaveClick = handleSubmit(async (data: ConnectionSetting) => {
    handleSave(data);
  });

  useEffect(() => {
    const maxConnections =
      sites.find((site) => site.siteId === selectedSiteId)?.connectionLimit ??
      connectionLimit;
    setValue("connectionLimit", maxConnections);
  }, [connectionLimit, selectedSiteId, setValue, sites]);

  const isIpExists = (ip: string) => {
    return settings?.some(
      (setting) => setting.ip === ip && connectionSetting.ip !== ip
    );
  };

  return (
    <React.Fragment>
      <FormHeader
        title={`HTTP/S Connection Setting`}
        onClose={handleCancel}
      ></FormHeader>
      <FormProvider {...methods}>
        <form noValidate>
          <Grid container spacing={2} className={classes.formContainer}>
            <Grid item xs={6}>
              <TcTextField
                name="ip"
                label="IP Address *"
                rules={{
                  required: "IP Address is required",
                  pattern: {
                    value: constants.IP_ADDRESS_REGEX,
                    message: "Invalid IP",
                  },
                  validate: {
                    nameExists: (value: string) =>
                      isIpExists(value) ? "This IP is already used" : true,
                  },
                }}
              ></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name={`port`}
                label="Port *"
                rules={{ required: "Port is required" }}
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="username"
                label="Username*"
                rules={{
                  required: {
                    value: true,
                    message: "Username is required",
                  },
                  pattern: {
                    value: constants.NAME_REGEX,
                    message: "Invalid username",
                  },
                }}
              ></TcTextField>
            </Grid>

            <TcPasswordTextField
              name="password"
              gridSize={6}
              passwordLabel="Password *"
              rules={{
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters long",
                },
              }}
            ></TcPasswordTextField>

            <Grid item xs={6}>
              <TcSelectDropdown
                name="siteId"
                label="Site *"
                options={sites}
                optionKey="siteId"
                optionLabel="name"
                rules={{
                  required: "Site is required",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="connectionLimit"
                label="Max connections limit"
                type="number"
                rules={{
                  required: "This value is required",
                  max: 100,
                  min: 1,
                }}
              ></TcTextField>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.formContainer}>
            <Grid item xs={2}>
              <Button
                size="small"
                type="submit"
                onClick={handleSaveClick}
                fullWidth
                variant="contained"
                color="info"
                id="save"
                disabled={!isValid || !isDirty}
              >
                {"Save"}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                color="info"
                onClick={handleCancel}
                id="cancel"
              >
                {"Cancel"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};

export default HttpsConnectionForm;
