import React, { useState } from "react";
import { Stack, Switch, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setSnackbarToast } from "redux/UiStateSlice";

import { Device, DevicesGroupFilterData } from "../types";
import { DeviceGroupType } from "common/enums";
import StaticToDynamicDialog from "./StaticToDynamicDialog";
import DynamicToStaticDialog from "./DynamicToStaticDialog";

type GroupTypeProps = {
  onCancel: (type: DeviceGroupType) => void;
  onConfirmSave: (type: DeviceGroupType) => void;
  rows: Device[];
  rowSelectionModel: string[];
  defaultGroupType: DeviceGroupType;
  isFilterSubmitted: boolean;
  filters: DevicesGroupFilterData;
};
const GroupType: React.FC<GroupTypeProps> = ({
  onCancel,
  onConfirmSave,
  rows,
  rowSelectionModel,
  defaultGroupType,
  filters,
  isFilterSubmitted,
}) => {
  const [showStaticToDynamicDialog, setShowStaticToDynamicDialog] =
    useState(false);
  const [showDynamicToStaticDialog, setShowDynamicToStaticDialog] =
    useState(false);
  const [groupTypeSwitchValue, setGroupTypeSwitchValue] =
    useState(defaultGroupType);
  const [newAddedDevicesOnTypeChange, setNewAddedDevicesOnTypeChange] =
    useState<Device[]>([]);
  const dispatch = useDispatch();

  const isFilterSelected = () => {
    if (!isFilterSubmitted) {
      return false;
    }
    if (
      filters.accessMethods.length > 0 ||
      filters.manufacturers.length > 0 ||
      filters.sites.length > 0 ||
      filters?.statuses.length > 0 ||
      filters?.types.length > 0
    ) {
      return true;
    }
  };
  const handleGroupTypeSwitchChange = () => {
    if (groupTypeSwitchValue === DeviceGroupType.STATIC) {
      //static to dynamic
      //show warning if user has not applied filter OR not selected all rows
      if (isFilterSelected() || rows.length === rowSelectionModel.length) {
        //find new added rows
        const newAddedDevices = rows.filter(
          (device) =>
            !rowSelectionModel.some(
              (originalObj) => originalObj === device.deviceId
            )
        );
        setNewAddedDevicesOnTypeChange(newAddedDevices);
        setShowStaticToDynamicDialog(true);
      } else {
        dispatch(
          setSnackbarToast({
            message: "Please filter devices before switching to Dynamic type",
            open: true,
            severity: "error",
          })
        );
      }
    } else {
      setShowDynamicToStaticDialog(true);
    }
  };

  const onConfirmStaticToDynamic = () => {
    setGroupTypeSwitchValue(DeviceGroupType.DYNAMIC);
    setShowStaticToDynamicDialog(false);
    onConfirmSave(DeviceGroupType.DYNAMIC);
  };

  const onCancelStaticToDynamic = () => {
    setShowStaticToDynamicDialog(false);
    onCancel(DeviceGroupType.STATIC);
  };

  const onConfirmDynamicToStatic = () => {
    setGroupTypeSwitchValue(DeviceGroupType.STATIC);
    setShowDynamicToStaticDialog(false);
    onConfirmSave(DeviceGroupType.STATIC);
  };

  const onCancelDynamicToStatic = () => {
    setShowDynamicToStaticDialog(false);
    onCancel(DeviceGroupType.DYNAMIC);
  };

  return (
    <React.Fragment>
      <Stack direction="row" alignItems="center">
        <Typography>
          <strong>Group Type: </strong>&nbsp;&nbsp;
        </Typography>
        <Typography variant="subtitle1">Static</Typography>
        <Switch
          checked={groupTypeSwitchValue === DeviceGroupType.DYNAMIC}
          onChange={handleGroupTypeSwitchChange}
        />
        <Typography variant="subtitle1">Dynamic</Typography>
      </Stack>

      {showStaticToDynamicDialog && (
        <StaticToDynamicDialog
          newDevices={newAddedDevicesOnTypeChange}
          onConfirm={onConfirmStaticToDynamic}
          open={showStaticToDynamicDialog}
          onCancel={onCancelStaticToDynamic}
        ></StaticToDynamicDialog>
      )}

      {showDynamicToStaticDialog && (
        <DynamicToStaticDialog
          onConfirm={onConfirmDynamicToStatic}
          open={showDynamicToStaticDialog}
          onCancel={onCancelDynamicToStatic}
        ></DynamicToStaticDialog>
      )}
    </React.Fragment>
  );
};
export default GroupType;
