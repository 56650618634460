"use client";

import { Button, Typography, Box } from "@mui/material";
import { useErrorBoundary } from "react-error-boundary";

function ErrorFallback({ error }: any) {
  const { resetBoundary } = useErrorBoundary();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Box
        p={2}
        bgcolor="background.paper"
        boxShadow={3}
        borderRadius={8}
        justifyContent="center"
        alignItems="center"
        padding={"5%"}
        width={"50%"}
      >
        <Typography variant="h5" color="error" align="center">
          {error.message ?? "Something went wrong!"}
        </Typography>
        <Box display="flex" justifyContent="center" mt={2}>
          <Button onClick={resetBoundary} color="info" variant="outlined">
            {"Try again"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default ErrorFallback;
