import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import AddOperator from "./AddOperator";

type AddOperatorDialogProps = {
  open: boolean;
  onCancel: () => void;
  onSave: () => void;
};

const AddOperatorDialog: React.FC<AddOperatorDialogProps> = ({
  open,
  onCancel,
  onSave,
}) => {
  return (
    <React.Fragment>
      <Dialog open={open}>
        <DialogContent>
          <AddOperator
            onClose={onCancel}
            onSaveUpdateTable={onSave}
          ></AddOperator>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
export default AddOperatorDialog;
