import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

import { SiteOwner } from "pages/settings/types";

type DeleteSiteOwnerProps = {
  selectedSiteOwner: SiteOwner;
  open: boolean;
  onCancelRemove: () => void;
  onDeleteSiteOwner: () => void;
};

const DeleteSiteOwner: React.FC<DeleteSiteOwnerProps> = (props) => {
  const handleRemove = () => {
    props.onDeleteSiteOwner();
  };

  return (
    <React.Fragment>
      <Dialog open={props.open}>
        <DialogTitle>
          {`Delete Site Owner '${props.selectedSiteOwner.name}' ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {"This will remove the Site Owner."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.onCancelRemove}
            color="info"
            variant="outlined"
          >
            {"Cancel"}
          </Button>
          <Button
            onClick={handleRemove}
            color="warning"
            autoFocus
            variant="contained"
          >
            {"Remove"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default DeleteSiteOwner;
