import React from "react";
import { Chip, Grid, Button } from "@mui/material";
import { DeviceFormMasterData, DevicesGroupFilterData } from "../types";
import { DeviceStatus } from "common/enums";

interface DevicesFilterChipDisplayProps {
  filters: DevicesGroupFilterData;
  deviceFormMasterData: DeviceFormMasterData;
  handleUpdatedFilter: (filters: DevicesGroupFilterData) => void;
  openFilter: () => void;
}

const deviceStatuses = [
  { id: DeviceStatus.ONLINE, name: DeviceStatus.ONLINE },
  { id: DeviceStatus.OFFLINE, name: DeviceStatus.OFFLINE },
];

const DevicesFilterChipDisplay: React.FC<DevicesFilterChipDisplayProps> = ({
  filters,
  handleUpdatedFilter,
  deviceFormMasterData,
  openFilter,
}) => {
  const handleFilterOptionDelete = (
    option: string,
    filtersData: string[],
    type: keyof DevicesGroupFilterData
  ) => {
    const updated = filtersData.filter((method) => method !== option);
    handleUpdatedFilter({ ...filters, [type]: updated });
  };

  const renderChips = (
    data: string[],
    type: keyof DevicesGroupFilterData,
    findOption: (
      value: string
    ) => { id?: string; name?: string; label?: string } | undefined
  ) => {
    return data.map((value) => {
      const option = findOption(value);
      return (
        <Chip
          variant="outlined"
          key={value}
          label={option?.name}
          onDelete={() => handleFilterOptionDelete(value, data, type)}
        />
      );
    });
  };

  const chips = [
    ...renderChips(filters.groups, "groups", (value) =>
      deviceFormMasterData.groups.find((opt) => opt.groupId === value)
    ),
    ...renderChips(filters.accessMethods, "accessMethods", (value) =>
      deviceFormMasterData.accessMethods.find((opt) => opt.name === value)
    ),
    ...renderChips(filters.manufacturers, "manufacturers", (value) =>
      deviceFormMasterData.manufacturers.find(
        (opt) => opt.manufacturerId === value
      )
    ),
    ...renderChips(filters.statuses, "statuses", (value) =>
      deviceStatuses.find((opt) => opt.id === value)
    ),
    ...renderChips(filters.types, "types", (value) =>
      deviceFormMasterData.deviceTypes.find((opt) => opt.deviceTypeId === value)
    ),
    ...renderChips(filters.sites, "sites", (value) =>
      deviceFormMasterData.sites.find((opt) => opt.siteId === value)
    ),
    ...renderChips(filters.owners, "owners", (value) =>
      deviceFormMasterData.owners.find((opt) => opt.ownerId === value)
    ),
    ...renderChips(filters.operators, "operators", (value) =>
      deviceFormMasterData.operators.find((opt) => opt.operatorId === value)
    ),
  ];

  return (
    <Grid container spacing={1} paddingBottom={1} paddingLeft={"2px"}>
      {chips.slice(0, 7).map((chip, index) => (
        <Grid item key={index}>
          {chip}
        </Grid>
      ))}
      {chips.length > 7 && (
        <>
          <Grid item>...</Grid>
          <Button onClick={openFilter} color="info">
            Show All
          </Button>
        </>
      )}
    </Grid>
  );
};

export default DevicesFilterChipDisplay;
