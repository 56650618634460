import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RuleFilter } from "pages/trust/types";

export interface IRulesFiltersState {
  filters: RuleFilter;
}

const initialState: IRulesFiltersState = {
  filters: {
    userGroups: [],
    devicesGroups: [],
    accessProfiles: [],
    statuses: [],
  },
};

const rulesFiltersSlice = createSlice({
  name: "rulesFilters",
  initialState,
  reducers: {
    setRulesFilters(state, action: PayloadAction<RuleFilter>) {
      state.filters = action.payload;
    },
  },
});

export const { setRulesFilters } = rulesFiltersSlice.actions;

export default rulesFiltersSlice.reducer;
