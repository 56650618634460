import * as React from "react";
import { useEffect } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { constants } from "common/constants";
import { SiteOperator } from "pages/settings/types";
import { hasPermission } from "common/helpers/utils";
import NoRowsOverlay from "common/components/NoRowsOverlay";
import { CustomToolbarWithOnlySearch } from "common/components/CustomToolbarWithOnlySearch";

type OperatorsTableProps = {
  data: SiteOperator[];
  handleEditOperator: (operator: SiteOperator) => void;
  handleDeleteOperator: (operator: SiteOperator) => void;
};

const OperatorsTable: React.FC<OperatorsTableProps> = (props) => {
  const [rows, setRows] = React.useState<SiteOperator[]>(props.data);

  useEffect(() => {
    setRows(props.data);
  }, [props.data]);

  const editOperator = React.useCallback(
    (gridRow: any) => () => {
      props.handleEditOperator(gridRow.row);
    },
    [props]
  );

  const deleteOperator = React.useCallback(
    (gridRow: any) => () => {
      props.handleDeleteOperator(gridRow.row);
    },
    [props]
  );

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "name",
        type: "string",
        headerName: "Operator Name",
        flex: 1,
      },
      {
        field: "description",
        type: "string",
        headerName: "Description",
        flex: 1,
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        flex: 1,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon color="info" />}
            label="Edit"
            disabled={!hasPermission("operators", "write")}
            onClick={editOperator(params)}
            showInMenu
            key="editOperator"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon color="info" />}
            disabled={!hasPermission("operators", "delete")}
            label="Delete"
            onClick={deleteOperator(params)}
            showInMenu
            key="deleteOperator"
          />,
        ],
      },
    ],
    [deleteOperator, editOperator]
  );

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      getRowId={(row) => row.operatorId}
      pageSizeOptions={constants.PAGE_SIZE_OPTIONS}
      initialState={{
        pagination: { paginationModel: constants.PAGINATION_MODEL },
      }}
      autoHeight
      slots={{
        toolbar: CustomToolbarWithOnlySearch,
        noRowsOverlay: () => (
          <NoRowsOverlay
            hasAccess={hasPermission("operators", "read")}
            name="Operators"
          />
        ),
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      sx={{
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: "600",
        },
      }}
    />
  );
};

export default OperatorsTable;
