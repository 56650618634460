import { IconButton, Typography } from "@mui/material";
import { ReactElement } from "react";
import RestoreIcon from "@mui/icons-material/Restore";

type ResetButtonProps = {
  handleResetClick: () => void;
};

const ResetButton = ({ handleResetClick }: ResetButtonProps): ReactElement => {
  return (
    <IconButton
      onClick={handleResetClick}
      color="info"
      size="small"
      aria-label="Reset"
    >
      <RestoreIcon />
      <Typography variant="body2" style={{ marginLeft: 4 }}>
        Reset
      </Typography>
    </IconButton>
  );
};

export default ResetButton;
