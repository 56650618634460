import React from "react";

import { CssBaseline, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import apiClient from "common/apiClientAxios";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import TcTextField from "common/components/TcTextField";
import { useDrawerFormStyles } from "common/styles/useDrawerFormStyles";
import { constants } from "common/constants";
import FormHeader from "common/components/FormHeader";
import { SiteOwner } from "pages/settings/types";

type AddSiteOwnerProps = {
  onClose: () => void;
  onSaveUpdateTable: () => void;
};

const AddSiteOwner: React.FC<AddSiteOwnerProps> = (props) => {
  const classes = useDrawerFormStyles();
  const dispatch = useDispatch();
  const onClose = () => {
    props.onClose();
  };

  const defaultValues: SiteOwner = {
    name: "",
    description: "",
    ownerId: "",
  };

  const methods = useForm<SiteOwner>({
    defaultValues,
    mode: "onBlur",
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const onUpdate = handleSubmit(async (data: SiteOwner) => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      const trustSiteOwnersResponse = await apiClient.post(
        `/site-owners`,
        data
      );
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      dispatch(
        setSnackbarToast({
          message: trustSiteOwnersResponse.data.meta.message,
          open: true,
          severity: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
    props.onSaveUpdateTable();
  });

  return (
    <React.Fragment>
      <CssBaseline />
      <FormHeader title={`Add Site Owner`} onClose={onClose}></FormHeader>
      <FormProvider {...methods}>
        <form noValidate>
          <Grid container spacing={2} className={classes.formContainer}>
            <Grid item xs={12}>
              <TcTextField
                name="name"
                label="Name *"
                rules={{
                  required: "Name is required",
                  pattern: {
                    value: constants.NAME_REGEX,
                    message: "Invalid name",
                  },
                }}
              ></TcTextField>
            </Grid>
            <Grid item xs={12}>
              <TcTextField
                name="description"
                label="Description"
                multiline
                rows={2}
              ></TcTextField>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.bottomDivider}>
            <Grid item xs={2}>
              <Button
                size="small"
                type="submit"
                disabled={!isValid}
                onClick={onUpdate}
                fullWidth
                variant="contained"
                color="info"
              >
                {"Save"}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                color="info"
                onClick={onClose}
              >
                {"Cancel"}
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};

export default AddSiteOwner;
