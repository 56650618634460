import { Grid, Button } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import TcTextField from "common/components/TcTextField";
import apiClient from "common/apiClientAxios";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import { User } from "pages/users/types";
import { ProfileForm } from "../types";
import { constants } from "common/constants";
import { useDispatch } from "react-redux";

type EditProfileFormProps = {
  user: User;
  onCancelEdit: () => void;
  onUpdateUser: () => void;
};

const EditProfileForm: React.FC<EditProfileFormProps> = ({
  user,
  onCancelEdit,
  onUpdateUser,
}) => {
  const dispatch = useDispatch();
  const methods = useForm<ProfileForm>({
    defaultValues: {
      ...user,
    },
    mode: "onBlur",
  });
  const {
    handleSubmit,
    formState: { isValid, isDirty },
  } = methods;

  const handleSaveClick = handleSubmit(async (data: ProfileForm) => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      const userResponse = await apiClient.put(
        `/users/profile/${user.userId}`,
        {
          ...data,
        }
      );
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      dispatch(
        setSnackbarToast({
          message: userResponse.data.meta.message,
          open: true,
          severity: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
    onUpdateUser();
  });
  return (
    <FormProvider {...methods}>
      <form noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TcTextField
              name="email"
              label="Email*"
              rules={{
                required: {
                  value: true,
                  message: "Email Address required",
                },
                pattern: {
                  value: constants.EMAIL_REGEX,
                  message: "Invalid Email Address",
                },
              }}
            ></TcTextField>
          </Grid>
          <Grid item xs={12}>
            <TcTextField
              name="firstName"
              label="First Name*"
              rules={{
                required: {
                  value: true,
                  message: "First name is required",
                },
                pattern: {
                  value: constants.USER_NAME_REGEX,
                  message: "Invalid First name",
                },
              }}
            ></TcTextField>
          </Grid>
          <Grid item xs={12}>
            <TcTextField
              name="lastName"
              label="Last Name*"
              rules={{
                required: {
                  value: true,
                  message: "Last name is required",
                },
                pattern: {
                  value: constants.USER_NAME_REGEX,
                  message: "Invalid Last name",
                },
              }}
            ></TcTextField>
          </Grid>
          <Grid item xs={12}>
            <TcTextField
              name="middleName"
              label="Middle Name"
              rules={{
                pattern: {
                  value: constants.USER_NAME_REGEX,
                  message: "Invalid Last name",
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TcTextField
              name="phone"
              label="Contact Phone"
              rules={{
                pattern: {
                  value: constants.PHONE_REGEX,
                  message: "Invalid contact number",
                },
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              size="small"
              type="submit"
              onClick={handleSaveClick}
              fullWidth
              variant="contained"
              color="info"
              id="save"
              disabled={!isValid || !isDirty}
            >
              {"Save"}
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              size="small"
              fullWidth
              variant="outlined"
              color="info"
              onClick={onCancelEdit}
              id="cancel"
            >
              {"Cancel"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default EditProfileForm;
