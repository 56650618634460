import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

import { Site } from "../types";

type DeleteSiteProps = {
  selectedSite: Site;
  open: boolean;
  onCancelRemove: () => void;
  onDeleteSite: () => void;
};

const DeleteSite: React.FC<DeleteSiteProps> = (props) => {
  const handleRemove = () => {
    props.onDeleteSite();
  };

  return (
    <React.Fragment>
      <Dialog open={props.open}>
        <DialogTitle>
          {`Delete site '${props.selectedSite.name}' ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{"This will remove the site."}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.onCancelRemove}
            color="info"
            variant="outlined"
          >
            {"Cancel"}
          </Button>
          <Button
            onClick={handleRemove}
            color="warning"
            autoFocus
            variant="contained"
          >
            {"Remove"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default DeleteSite;
