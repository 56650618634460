import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

type DeviceTypeDialogProps = {
  open: boolean;
  dialogContent: string;
  onCancel: () => void;
  onConfirmType: () => void;
  isEdit?: boolean;
};
const DeviceTypeDialog: React.FC<DeviceTypeDialogProps> = ({
  open,
  dialogContent,
  onCancel,
  onConfirmType,
  isEdit = false,
}) => {
  return (
    <React.Fragment>
      <Dialog open={open}>
        <DialogContent>
          <DialogContentText>{dialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="info" variant="outlined">
            {"Cancel"}
          </Button>
          <Button
            onClick={onConfirmType}
            color="warning"
            autoFocus
            variant="contained"
          >
            {isEdit ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default DeviceTypeDialog;
