import { FC } from "react";

import { TextField, TextFieldProps } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { get } from "lodash";

type TcTextFieldProps = {
  name: string;
  rules?: Record<string, any>;
  defaultValue?: string | number | null;
} & TextFieldProps;

const TcTextField: FC<TcTextFieldProps> = ({ name, rules, ...otherProps }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={otherProps.defaultValue ?? ""}
      render={({ field }) => (
        <TextField
          {...field}
          {...otherProps}
          size="small"
          variant="outlined"
          fullWidth
          error={Boolean(get(errors, name))}
          helperText={
            get(errors, name)
              ? (get(errors, name)?.message as unknown as string)
              : ""
          }
        />
      )}
      rules={rules}
    />
  );
};

export default TcTextField;
