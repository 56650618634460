import Tab from "@mui/material/Tab";
import React from "react";
import { Card, Box } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import SiteLANTab from "./SiteLAN";
import SiteWANTab from "./SiteWAN";
import CellularTab from "./Cellular";
import SecurityTab from "./Security";
import VPNTab from "./VPN";
import FirewallTab from "./Firewall";
import OPNConfigTab from "./OPNConfig";
import { Device } from "../types";

const demoConfigData = {
  state: "Connected",
  rssi: "0.0dBm",
  provider: "VzW",
  apn: "we01.vzwstatic",
  ip: "104.107.62.205",
  number: "17077914215",
  imei: "xxxxxxxxxxxxx",
  accessTechnology: "3G LTE",
  model: "MC7455",
  manufacturer: "Sierra Wireless, Inc.",
  hardwareVersion: "1.0",
  firmwareVersion:
    "SWI9X30C_02.20.03.00 r6691 CARMD-EV-FRMWR2 2016/06/30 10:54:05",
};
interface GatewayDeviceDetailsProps {
  device: Device;
}
const GatewayDeviceDetails: React.FC<GatewayDeviceDetailsProps> = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Card elevation={0}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                textColor="primary"
                indicatorColor="secondary"
              >
                <Tab label="Security" value="1" />
                <Tab label="Site LAN" value="2" />
                <Tab label="Site WAN" value="3" />
                <Tab label="Cellular" value="4" />
                <Tab label="VPN" value="5" />
                <Tab label="Firewall" value="6" />
                <Tab label="OPN Config" value="7" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <SecurityTab></SecurityTab>
            </TabPanel>
            <TabPanel value="2">
              <SiteLANTab></SiteLANTab>
            </TabPanel>
            <TabPanel value="3">
              <SiteWANTab></SiteWANTab>
            </TabPanel>
            <TabPanel value="4">
              <CellularTab information={demoConfigData}></CellularTab>
            </TabPanel>
            <TabPanel value="5">
              <VPNTab></VPNTab>
            </TabPanel>
            <TabPanel value="6">
              <FirewallTab></FirewallTab>
            </TabPanel>
            <TabPanel value="7">
              <OPNConfigTab></OPNConfigTab>
            </TabPanel>
          </TabContext>
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default GatewayDeviceDetails;
