import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  TextField,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import TcPasswordField from "common/components/TcPasswordField";
import { ResetPasswordForm } from "../types";
import apiClient from "common/apiClientAxios";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import { User } from "pages/users/types";
import { useContentStyles } from "common/styles/useContentStyles";
import { LoginFeature } from "common/enums";
import { RootState } from "redux/Store";
import { constants } from "common/constants";

type ResetPasswordTabProps = {
  user: User;
  onUpdatePassword: () => void;
  onCancel: () => void;
};

const ResetPasswordTab: React.FC<ResetPasswordTabProps> = ({
  user,
  onUpdatePassword,
}) => {
  const classes = useContentStyles();
  const dispatch = useDispatch();
  const methods = useForm<ResetPasswordForm>({
    defaultValues: {
      existingPassword: "",
      newPassword: "",
    },
    mode: "onBlur",
  });
  const {
    handleSubmit,
    formState: { isValid, errors },
  } = methods;

  const [showExistingPassword, setShowExistingPassword] = React.useState(false);
  const { availableLogins } = useSelector(
    (state: RootState) => state.loginSettingsState
  );

  const toggleExistingPasswordVisibility = () => {
    setShowExistingPassword(!showExistingPassword);
  };

  const handleSaveClick = handleSubmit(async (data: ResetPasswordForm) => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      const userResponse = await apiClient.put(
        `/users/${user.userId}/resetPassword`,
        {
          ...data,
        }
      );
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      dispatch(
        setSnackbarToast({
          message: userResponse.data.meta.message,
          open: true,
          severity: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
    onUpdatePassword();
  });
  return (
    <Card variant="outlined">
      <CardHeader
        title="Reset Password"
        disableTypography
        className={classes.cardHeader}
      ></CardHeader>
      <CardContent>
        <FormProvider {...methods}>
          <form noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="existingPassword"
                  defaultValue=""
                  rules={{
                    required: "Password is required",
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters long",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Existing Password"
                      disabled={availableLogins.includes(LoginFeature.AZURE)}
                      size="small"
                      type={showExistingPassword ? "text" : "password"}
                      variant="outlined"
                      fullWidth
                      error={!!errors.existingPassword}
                      helperText={
                        errors.existingPassword
                          ? (errors.existingPassword
                              .message as unknown as string)
                          : ""
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              disabled={availableLogins.includes(
                                LoginFeature.AZURE
                              )}
                              onClick={toggleExistingPasswordVisibility}
                            >
                              {showExistingPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <TcPasswordField
                name="newPassword"
                disabled={availableLogins.includes(LoginFeature.AZURE)}
                gridSize={12}
                passwordLabel="New Password"
                confirmPasswordLabel="Confirm New Password"
                rules={{
                  required: "Existing password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters long",
                  },
                }}
              ></TcPasswordField>
              <Grid item xs={2}>
                <Button
                  size="small"
                  type="submit"
                  onClick={handleSaveClick}
                  fullWidth
                  variant="contained"
                  color="info"
                  id="save"
                  disabled={!isValid}
                >
                  {"Save"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </CardContent>
    </Card>
  );
};

export default ResetPasswordTab;
