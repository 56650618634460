import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

import { User } from "./types";

type DeleteUserProps = {
  selectedUser: User;
  open: boolean;
  onCancelRemove: () => void;
  onDeleteUser: () => void;
};

const DeleteUser: React.FC<DeleteUserProps> = (props) => {
  const handleRemove = () => {
    props.onDeleteUser();
  };

  return (
    <React.Fragment>
      <Dialog open={props.open}>
        <DialogTitle id="alert-dialog-title">
          {`Delete user '${props.selectedUser.firstName ?? "-"} ${
            props.selectedUser.lastName ?? "-"
          }' ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{"This will remove the user."}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.onCancelRemove}
            color="info"
            variant="outlined"
          >
            {"Cancel"}
          </Button>
          <Button
            onClick={handleRemove}
            color="warning"
            autoFocus
            variant="contained"
          >
            {"Remove"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default DeleteUser;
