import React from "react";
import { Chip, Grid, Button } from "@mui/material";
import { Country, SiteFilterData, SiteFormMasterData, State } from "../types";

interface SitesFilterChipDisplayProps {
  filters: SiteFilterData;
  siteFormMasterData: SiteFormMasterData;
  handleUpdatedFilter: (filters: SiteFilterData) => void;
  openFilter: () => void;
}

const SitesFilterChipDisplay: React.FC<SitesFilterChipDisplayProps> = ({
  filters,
  handleUpdatedFilter,
  siteFormMasterData,
  openFilter,
}) => {
  const handleFilterOptionDelete = (
    option: string,
    filtersData: string[],
    type: keyof SiteFilterData,
    countries?: State[] | Country[]
  ) => {
    switch (type) {
      case "selectedOwners":
      case "selectedOperators":
        handleUpdatedFilter({
          ...filters,
          [type]: filtersData.filter((filterOption) => filterOption !== option),
        });
        break;
      case "selectedCountries":
        {
          const updated = (countries as Country[]).filter(
            (country) => country.countryId !== option
          );
          handleUpdatedFilter({ ...filters, selectedCountries: updated });
        }
        break;
      case "selectedStates":
        {
          const updated = (countries as State[]).filter(
            (state) => state.stateId !== option
          );
          handleUpdatedFilter({ ...filters, selectedStates: updated });
        }
        break;
      case "city":
        handleUpdatedFilter({ ...filters, city: "" });
        break;
      default:
        break;
    }
  };

  const renderChip = (
    type: keyof SiteFilterData,
    label: string,
    optionId: string,
    filtersData: string[] = [],
    countries?: State[] | Country[]
  ) => (
    <Grid item key={`${type}${optionId}`}>
      <Chip
        variant="outlined"
        label={label}
        onDelete={() =>
          handleFilterOptionDelete(optionId, filtersData, type, countries)
        }
      />
    </Grid>
  );

  const chips = [
    ...filters.selectedCountries.map((value) =>
      renderChip(
        "selectedCountries",
        value?.name,
        value.countryId,
        [],
        filters.selectedCountries
      )
    ),
    ...filters.selectedStates.map((value) =>
      renderChip(
        "selectedStates",
        value?.name,
        value.stateId,
        [],
        filters.selectedStates
      )
    ),
    filters.city && renderChip("city", filters.city, "city"),
    ...filters.selectedOwners.map((value) => {
      const option = siteFormMasterData.siteOwners.find(
        (opt) => opt.ownerId === value
      );
      return renderChip(
        "selectedOwners",
        option?.name || "",
        option?.ownerId || "",
        filters.selectedOwners
      );
    }),
    ...filters.selectedOperators.map((value) => {
      const option = siteFormMasterData.siteOperators.find(
        (opt) => opt.operatorId === value
      );
      return renderChip(
        "selectedOperators",
        option?.name || "",
        option?.operatorId || "",
        filters.selectedOperators
      );
    }),
  ].filter(Boolean);

  return (
    <Grid container spacing={1} paddingBottom={1} paddingLeft={"2px"}>
      {chips.slice(0, 7).map((chip, index) => (
        <Grid item key={index}>
          {chip}
        </Grid>
      ))}
      {chips.length > 7 && (
        <>
          <Grid item>...</Grid>
          <Grid item>
            <Button onClick={openFilter} color="info">
              {"Show All"}
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default SitesFilterChipDisplay;
