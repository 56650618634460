import * as React from "react";
import { useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import { constants } from "common/constants";
import NoRowsOverlay from "common/components/NoRowsOverlay";
import { CustomToolbarWithOnlySearch } from "common/components/CustomToolbarWithOnlySearch";
import { ConnectionSetting } from "../types";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

type HttpsConnectionsTableProps = {
  data: ConnectionSetting[];
  handleEdit: (setting: ConnectionSetting) => void;
  handleDelete: (setting: ConnectionSetting) => void;
  connectionLimit: string;
};

const HttpsConnectionsTable: React.FC<HttpsConnectionsTableProps> = ({
  data,
  handleDelete,
  handleEdit,
  connectionLimit,
}) => {
  const [rows, setRows] = React.useState<ConnectionSetting[]>(data);

  useEffect(() => {
    setRows(data);
  }, [data]);

  const editRow = React.useCallback(
    (gridRow: any) => () => {
      handleEdit(gridRow.row);
    },
    [handleEdit]
  );

  const deleteRow = React.useCallback(
    (gridRow: any) => () => {
      handleDelete(gridRow.row);
    },
    [handleDelete]
  );

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "ip",
        type: "string",
        headerName: "Device IP",
        flex: 1,
        renderCell: (params) => {
          const { row } = params;
          const count = rows.filter(
            (item) => item.siteId === row.siteId
          ).length;
          return (
            <Box display="flex" alignItems="center">
              {row.ip}
              {count >
                (row.connectionLimit
                  ? +row.connectionLimit
                  : +connectionLimit) && (
                <Tooltip
                  title={constants.DELETE_CONNECTION_WARNING}
                  placement="top"
                >
                  <WarningAmberIcon color="warning" style={{ marginLeft: 8 }} />
                </Tooltip>
              )}
            </Box>
          );
        },
      },
      {
        field: "port",
        type: "string",
        headerName: "Device Port",
        flex: 1,
      },
      {
        field: "username",
        type: "string",
        headerName: "Username",
        flex: 1,
      },
      {
        field: "password",
        type: "string",
        headerName: "Password",
        flex: 1,
        renderCell: () => <>************</>,
      },
      {
        field: "siteName",
        type: "string",
        headerName: "Site",
        flex: 1,
      },
      {
        field: "connectionLimit",
        type: "string",
        headerName: "Max connections",
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        flex: 1,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon color="info" />}
            label="Edit"
            onClick={editRow(params)}
            showInMenu
            disabled
            key="editRow"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon color="info" />}
            label="Delete"
            onClick={deleteRow(params)}
            showInMenu
            disabled
            key="deleteRow"
          />,
        ],
      },
    ],
    [connectionLimit, deleteRow, editRow, rows]
  );

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      getRowId={(row) => row.id ?? ""}
      slots={{
        toolbar: CustomToolbarWithOnlySearch,
        noRowsOverlay: () => (
          <NoRowsOverlay name="Https Connection Settings" hasAccess={true} />
        ),
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      pageSizeOptions={constants.PAGE_SIZE_OPTIONS}
      initialState={{
        pagination: { paginationModel: constants.PAGINATION_MODEL },
      }}
      autoHeight
      sx={{
        "& .MuiDataGrid-row:hover": {
          cursor: "pointer",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: "600",
        },
      }}
    />
  );
};

export default HttpsConnectionsTable;
