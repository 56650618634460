import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  CssBaseline,
  Card,
  Link,
  Typography,
  CardHeader,
  Drawer,
  Button,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { useDispatch } from "react-redux";
import { setSnackbarToast } from "redux/UiStateSlice";

import { useContentStyles } from "common/styles/useContentStyles";
import apiClient from "common/apiClientAxios";
import AddRole from "./AddRole";
import DeleteRole from "./DeleteRole";
import EditRole from "./EditRole";
import RolesTable from "./RolesTable";
import { DrawerHeader } from "common/styles/styledComponents";
import { Role, RoleRouteFeature } from "pages/settings/types";
import { hasPermission } from "common/helpers/utils";

export const defaultPermissions = [
  {
    featureId: "sites.summary",
    featureName: "Sites",
    delete: false,
    read: false,
    write: false,
  },
  {
    featureId: "sites.groups",
    featureName: "Site Groups",
    delete: false,
    read: false,
    write: false,
  },
  {
    featureId: "devices.summary",
    featureName: "Devices",
    delete: false,
    read: false,
    write: false,
  },
  {
    featureId: "devices.groups",
    featureName: "Devices Groups",
    delete: false,
    read: false,
    write: false,
  },
  {
    featureId: "users.summary",
    featureName: "Users",
    delete: false,
    read: false,
    write: false,
  },
  {
    featureId: "users.groups",
    featureName: "Users Groups",
    delete: false,
    read: false,
    write: false,
  },
  {
    featureId: "trust.list",
    featureName: "Trust Rules",
    delete: false,
    read: false,
    write: false,
  },
  {
    featureId: "trust.zones",
    featureName: "Zones",
    delete: false,
    read: false,
    write: false,
  },
  {
    featureId: "logging",
    featureName: "Logging",
    delete: false,
    read: false,
    write: false,
  },
  {
    featureId: "viz",
    featureName: "Network Visibility",
    delete: false,
    read: false,
    write: false,
  },
  {
    featureId: "settings.theme",
    featureName: "Theme settings",
    delete: false,
    read: false,
    write: false,
  },
  {
    featureId: "settings.idp",
    featureName: "IDP Access settings",
    delete: false,
    read: false,
    write: false,
  },
  {
    featureId: "settings.features",
    featureName: "Features Settings",
    delete: false,
    read: false,
    write: false,
  },
  {
    featureId: "roles",
    featureName: "Roles",
    delete: false,
    read: false,
    write: false,
  },
  {
    featureId: "manufacturers",
    featureName: "Manufacturers",
    delete: false,
    read: false,
    write: false,
  },
  {
    featureId: "organizations",
    featureName: "Organizations",
    delete: false,
    read: false,
    write: false,
  },
  {
    featureId: "operators",
    featureName: "Site operators",
    delete: false,
    read: false,
    write: false,
  },
  {
    featureId: "owners",
    featureName: "Site owners",
    delete: false,
    read: false,
    write: false,
  },
];

export const defaultRoutePermissions: RoleRouteFeature[] = [
  {
    featureId: "sites",
    featureName: "Sites",
    hasAccess: false,
  },
  {
    featureId: "devices",
    featureName: "Devices",
    hasAccess: false,
  },
  {
    featureId: "users",
    featureName: "Users",
    hasAccess: false,
  },
  {
    featureId: "trust",
    featureName: "Trust Rules",
    hasAccess: false,
  },
  {
    featureId: "logging",
    featureName: "Logs",
    hasAccess: false,
  },
  {
    featureId: "viz",
    featureName: "Network Visibility",
    hasAccess: false,
  },
  {
    featureId: "settings",
    featureName: "Settings",
    hasAccess: false,
  },
];

const Roles: React.FC = () => {
  const classes = useContentStyles();
  const timerRef = useRef<number>();
  const dispatch = useDispatch();
  const TIMEOUT = 1000;
  const [openForm, setOpenForm] = useState(false);
  const [isUpdate, setUpdate] = useState(true);
  const [isEdit, setEdit] = useState(false);
  const [selectedRole, setSelectedRole] = useState<Role>({
    roleId: "",
    name: "",
  });
  const [openDialogue, setOpenDialogue] = React.useState(false);

  const [roles, setRoles] = useState<Role[]>([]);

  const updateTable = useCallback(async () => {
    try {
      const rolesResponse = await apiClient.get("/roles");
      setRoles(rolesResponse.data.data as Role[]);
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (isUpdate) {
      updateTable();
      setUpdate(false);
    }
    return () => clearTimeout(timerRef.current);
  }, [isUpdate, updateTable]);

  const openAddForm = () => {
    setOpenForm(true);
  };
  const onCloseForm = () => {
    setOpenForm(false);
  };
  const onCloseEdit = () => {
    setEdit(false);
  };

  const onSaveUpdateTable = () => {
    timerRef.current = window.setTimeout(() => {
      setEdit(false);
      setOpenForm(false);
      setUpdate(true);
    }, TIMEOUT);
  };

  const onDeleteRole = async () => {
    try {
      if (selectedRole.roleId) {
        const deleteResponse = await apiClient.delete(
          `/roles/${selectedRole.roleId}`
        );
        dispatch(
          setSnackbarToast({
            message: deleteResponse.data.meta.message,
            open: true,
            severity: "success",
          })
        );
        onDeleteUpdateTable();
      }
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
      setOpenDialogue(false);
    }
  };

  const handleDeleteRole = (obj: Role) => {
    setSelectedRole(obj);
    setOpenDialogue(true);
  };

  const onDeleteUpdateTable = () => {
    setOpenDialogue(false);
    setUpdate(true);
  };

  const onCancelRemove = () => {
    setOpenDialogue(false);
  };

  const onEditRole = (obj: Role) => {
    setSelectedRole(obj);
    setEdit(true);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.contentPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="none" color="inherit">
            Resources
          </Link>
          <Typography variant="body1">{"Roles"}</Typography>
        </Breadcrumbs>
      </div>
      <Card elevation={0} className={classes.contentSection}>
        <CardHeader
          disableTypography
          title="Roles"
          className={classes.cardHeader}
          action={
            <Button
              onClick={openAddForm}
              color="info"
              disabled={!hasPermission("roles", "write")}
              startIcon={<PlaylistAddIcon />}
            >
              {"Add Role"}
            </Button>
          }
        ></CardHeader>
        <Drawer
          anchor="right"
          open={openForm}
          classes={{ paper: classes.formPaper }}
        >
          <DrawerHeader />
          <AddRole
            onSaveUpdateTable={onSaveUpdateTable}
            onClose={onCloseForm}
          ></AddRole>
        </Drawer>
        <Drawer
          anchor="right"
          open={isEdit}
          classes={{ paper: classes.formPaper }}
        >
          <DrawerHeader />
          <EditRole
            onSaveUpdateTable={onSaveUpdateTable}
            onClose={onCloseEdit}
            selectedRole={selectedRole}
          ></EditRole>
        </Drawer>
        {openDialogue && (
          <DeleteRole
            onDeleteRole={onDeleteRole}
            selectedRole={selectedRole}
            open={openDialogue}
            onCancelRemove={onCancelRemove}
          ></DeleteRole>
        )}
        <RolesTable
          data={roles}
          handleEditRole={onEditRole}
          handleDeleteRole={handleDeleteRole}
        ></RolesTable>
      </Card>
    </React.Fragment>
  );
};

export default Roles;
