import React, { useCallback, useState, useEffect } from "react";
import {
  CssBaseline,
  Card,
  Link,
  Typography,
  Button,
  CardHeader,
  Box,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import apiClient from "common/apiClientAxios";
import DeleteGroup from "./DeleteDeviceGroup";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import { DeviceGroup } from "../types";
import { useContentStyles } from "common/styles/useContentStyles";
import DeviceGroupsTable from "./DeviceGroupsTable";
import {
  getAccessWindowDisplaySetting,
  hasPermission,
  isEndUser,
} from "common/helpers/utils";
import { constants } from "common/constants";
import { RootState } from "redux/Store";

const DeviceGroups: React.FC = () => {
  const classes = useContentStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isUpdate, setUpdate] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState<DeviceGroup>({
    groupId: "",
    name: "",
  });
  const [openDialogue, setOpenDialogue] = React.useState(false);

  const [groups, setGroups] = useState<DeviceGroup[]>([]);

  // update table
  const updateTable = useCallback(async () => {
    try {
      const groupsResponse = await apiClient.get("/devices-groups");
      setGroups(groupsResponse.data.data as DeviceGroup[]);
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (isUpdate) {
      updateTable();
      setUpdate(false);
    }
  }, [isUpdate, updateTable]);

  //on click of add group
  const openAddGroupForm = () => {
    navigate(`/portal/devices/summary`, { state: { isAddDeviceGroup: true } });
  };

  //delete selected group
  const onDeleteGroup = async () => {
    try {
      if (selectedGroup.groupId) {
        const groupDeleteResponse = await apiClient.delete(
          `/devices-groups/${selectedGroup.groupId}`
        );
        dispatch(
          setSnackbarToast({
            message: groupDeleteResponse.data.meta.message,
            open: true,
            severity: "success",
          })
        );
        onDeleteUpdateTable();
      }
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
      setOpenDialogue(false);
    }
  };

  const handleDeleteGroup = (groupObj: DeviceGroup) => {
    setSelectedGroup(groupObj);
    setOpenDialogue(true);
  };

  //refresh table after delete
  const onDeleteUpdateTable = () => {
    setOpenDialogue(false);
    setUpdate(true);
  };

  //close delete dialogue
  const onCancelRemove = () => {
    setOpenDialogue(false);
  };

  const handleFavoriteGroup = async (group: DeviceGroup) => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      if (group.isFavorite) {
        await apiClient.delete(`users/favoriteDeviceGroup/${group.groupId}`);
      } else {
        await apiClient.put(`users/favoriteDeviceGroup/${group.groupId}`);
      }
      const groupsResponse = await apiClient.get("/devices-groups");
      setGroups(groupsResponse.data.data as DeviceGroup[]);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  };
  const { userData } = useSelector((state: RootState) => state.userState);
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.contentPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="none" color="inherit">
            Devices
          </Link>
          <Typography variant="body1">{"Groups"}</Typography>
        </Breadcrumbs>
      </div>
      <Card elevation={0} className={classes.contentSection}>
        <CardHeader
          disableTypography
          title="Devices Groups"
          className={classes.cardHeader}
          action={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: (theme) => theme.palette.info.main,
              }}
            >
              <Box
                sx={{
                  paddingRight: 2,
                  marginRight: 2,
                }}
              >
                <Typography>
                  Times displayed in:{" "}
                  <strong>
                    {
                      getAccessWindowDisplaySetting(
                        userData.accessWindowDisplay
                      ).display
                    }
                  </strong>
                </Typography>
              </Box>
              {!isEndUser() && hasPermission("devices.groups", "write") && (
                <Button
                  onClick={openAddGroupForm}
                  color="info"
                  startIcon={<PlaylistAddIcon />}
                >
                  {"Create Group"}
                </Button>
              )}
            </Box>
          }
        ></CardHeader>
        {openDialogue && (
          <DeleteGroup
            onDeleteGroup={onDeleteGroup}
            selectedGroup={selectedGroup}
            open={openDialogue}
            onCancelRemove={onCancelRemove}
          ></DeleteGroup>
        )}
        <DeviceGroupsTable
          data={groups}
          handleDeleteGroup={handleDeleteGroup}
          handleFavoriteGroup={handleFavoriteGroup}
        ></DeviceGroupsTable>
      </Card>
    </React.Fragment>
  );
};

export default DeviceGroups;
