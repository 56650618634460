import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

type DynamicToStaticDialogProps = {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const DynamicToStaticDialog: React.FC<DynamicToStaticDialogProps> = ({
  open,
  onCancel,
  onConfirm,
}) => {
  return (
    <React.Fragment>
      <Dialog open={open}>
        <DialogContent>
          <DialogContentText>
            You are about to change from Dynamic to Static. The same group
            members will be retained, but not actively updated. Is this what you
            want?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="info" variant="outlined">
            {"No"}
          </Button>
          <Button
            onClick={onConfirm}
            color="info"
            autoFocus
            variant="contained"
          >
            {"Yes"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default DynamicToStaticDialog;
