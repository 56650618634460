import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  TextField,
  IconButton,
  InputAdornment,
  TextFieldProps,
  Grid,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

type TcPasswordFieldProps = {
  name: string;
  gridSize: number;
  rules: Record<string, any>;
  passwordLabel: string;
  confirmPasswordLabel: string;
  disabled?: boolean;
  helperText?: string;
} & TextFieldProps;

const TcPasswordField: FC<TcPasswordFieldProps> = ({
  name,
  gridSize,
  rules,
  passwordLabel,
  confirmPasswordLabel,
  disabled = false,
  helperText = "",
}) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const password = watch(name, "");

  const [showPassword, setShowPassword] = React.useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <React.Fragment>
      <Grid item xs={gridSize}>
        <Controller
          name={name}
          control={control}
          defaultValue=""
          rules={rules}
          render={({ field }) => (
            <TextField
              {...field}
              disabled={disabled}
              label={passwordLabel}
              size="small"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              fullWidth
              error={!!errors[name]}
              helperText={
                errors[name]
                  ? (errors[name]?.message as unknown as string)
                  : helperText
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={togglePasswordVisibility}
                      disabled={disabled}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={gridSize}>
        <Controller
          name="confirmPassword"
          control={control}
          defaultValue=""
          rules={{
            required: "Confirm Password is required",
            validate: (value) => value === password || "Passwords do not match",
          }}
          render={({ field }) => (
            <TextField
              {...field}
              disabled={disabled}
              label={confirmPasswordLabel}
              size="small"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              fullWidth
              error={!!errors.confirmPassword}
              helperText={
                errors.confirmPassword
                  ? (errors.confirmPassword.message as unknown as string)
                  : ""
              }
            />
          )}
        />
      </Grid>
    </React.Fragment>
  );
};

export default TcPasswordField;
